import React, { useState } from 'react';
import {Row,Col} from 'reactstrap';
import { CountAddress } from '../../data/addressInformation';
import Direction from './addressesForm';
import AddressList from './addressList';

const HelperDirection = ({ token ,checkOut}) => {

    const count = CountAddress();
    const [showList, setShowList] = useState(true);
    const [title, setTitle] = useState("Lista de direcciones");
    const [refresh, setRefresh] = useState(false);
    const [dataAddress, setdataAddress] = useState({});
    const [action, setAction] = useState("new");
    const [billingInformation, setbillingInformation] = useState(false);

    const AddNewDirection = () => {
        setAction("new");
        setShowList(false);
        setTitle("Nueva dirección");
        setbillingInformation(false);
        setdataAddress({
            address_name: "",
            street: "",
            suburb: "",
            country: "",
            interior_number: "",
            outdoor_number: "",
            postal_code: "",
            state: "",
            town: "",
            comment: "",
            status: true
        });
    }

    const EditDirection = (data) => {
        if (data.business_name !== null && data.rfc !== null && data.Invoicing_address) {
            setbillingInformation(true);
        } else {
            setbillingInformation(false);
        }
        setAction("update");
        setTitle("Editar dirección");
        setdataAddress(data);
        setShowList(false);
    }

    const handleSubmit = () => {
        setRefresh(true);
    }

    const CloseForm = () => {
        setTitle("Lista de direcciones");
        setShowList(true);
    }

    const DeleteDirection = (id) => {
        console.log("id a elminar:", id);
    }
    return (
        <section>
            <div className="container-fluid">
                <Row className="justify-content-center text-center">
                    <Col lg={8} md={10}>
                        <div className="mb-2">
                            <h6 className="text-primary mb-1">{title}</h6>
                        </div>
                    </Col>
                </Row>
                <Row >
                    {(count > 0 && showList === true) ?
                        <AddressList
                            flag={refresh}
                            AddNewDirection={AddNewDirection}
                            EditDirection={EditDirection}
                            DeleteDirection={DeleteDirection}
                            token={token}
                            checkOut={checkOut}
                        />
                        :
                        <Direction
                            CloseForm={CloseForm}
                            handleSubmit={handleSubmit}
                            dataAddress={dataAddress}
                            action={action}
                            token={token}
                            billingInformation={billingInformation}
                        />
                    }
                </Row>
            </div>
        </section>
    );
}

export default React.memo(HelperDirection);