import {gql} from '@apollo/client';

export const LIST_ADDRESS = gql `
query{
    addresses{
        id
        address_name
        street
        suburb
        country
        interior_number 
        outdoor_number
        postal_code
        state
        town
        comment
        business_name
        Invoicing_address
        rfc
    }
}
`;

export const FILTER_ADDRESS = gql `
query addresses($sort: String,$limit: Int,$start: Int,$where: JSON){
    addresses(sort:$sort,limit:$limit,start:$start,where:$where){
        id
        address_name
        street
        suburb
        country
        interior_number
        outdoor_number
        postal_code
        state
        town
        comment
        business_name
        Invoicing_address
        rfc
        status
    }
}
`;