import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    InputGroup,
    InputGroupAddon,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label
} from 'reactstrap';


const CustomerDataForm = ({ token }) => {

    const url = (window.location.hostname === 'localhost') ? 'http://localhost:1337' : 'https://backend.mayofy.com';
    const apiUrlCheckNumber = (window.location.hostname === 'localhost') ? 'http://127.0.0.1:8000/api/sendCode' : 'https://api.ecommpos.com/api/sendCode';
    const userdata = JSON.parse(localStorage.getItem("_user"));
    const [disabled, setDisabled] = useState(true);
    const [moreInputs, setMoreInputs] = useState(false);
    const [ValidCode, setValidCode] = useState(true);
    const [ValidNumber, setValidNumber] = useState(false);
    const [apiCode, setApiCode] = useState('');
    const [code, setCode] = useState('');
    const [telefono, setTelefono] = useState("");

    /* change color primary */
    const [data, setData] = useState({
        country: userdata.country,
        name: userdata.name,
        mail: userdata.mail,
        tel: userdata.tel,
    });

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    const updateData = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }

    const updateCode = e => {
        setCode(e.target.value);
    }

    const sentData = (event) => {
        event.preventDefault();
        axios({
            method: 'put',
            url: url + "/customers/" + parseInt(userdata.id),
            data: data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((res) => {
            updateLocalStorage();
            toast.success("Operación realizada con éxito");
        }).catch((err) => {
            toast.error("Error al realizar operación");
        });
    }

    const updateLocalStorage = () => {
        axios({
            method: 'get',
            url: url + "/customers/" + parseInt(userdata.id),
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((res) => {
            localStorage.setItem("_user", JSON.stringify(res.data));
        }).catch((err) => {
            console.log("error->", err);
        });
    }

    const SentCode = () => {
        axios({
            method: 'post',
            url: apiUrlCheckNumber,
            data: {
                tel: telefono,
                key: process.env.REACT_APP_API_KEY
            }
        }).then((res) => {
            if (res.status === 200) {
                if (res.data.length < 1 || res.data.error) {
                    toast.error("Error al enviar código");
                    return;
                }

                setApiCode(res.data.code);
                setValidCode(false);
                setValidNumber(true);
                toast.success("El código ha sido enviado al numero de teléfono: " + telefono);
                // Show alert
            } else {
                console.log('res status', res);
                // Show error
            }
        }).catch((err) => {
            console.log('err', err);
            toast.error("Error");
            // Show error
        })
    }

    const CheckNumber = () => {
        if (parseInt(code) !== apiCode) {
            toast.error("Código incorrecto");
            return;
        }
        toast.success("Validación realizada con éxito");
        setData({ data, tel: telefono });
        setMoreInputs(false);
        setDisabled(true);
        setValidNumber(false);
        setValidCode(true);
        setCode('');
        setModal(!modal);
        setTimeout(() => {
            setTelefono("");
        }, 2000);
    }

    const Cancel = () => {
        setValidNumber(false);
        setValidCode(true);
        setDisabled(true);
        setCode('');
        setTelefono("");
        setModal(!modal);
    }

    return (
        <Container>
            <Row>
                <Col lg={12} md={12} className="ml-auto mr-auto">
                    <Card>
                        <CardBody>
                            <div className="register-form" >
                                <form id="contact-form" onSubmit={sentData}>
                                    <div className="messages" />
                                    <Row>
                                        <Col md={6}>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    name="name"
                                                    className="form-control"
                                                    placeholder="Nombre"
                                                    required="required"
                                                    data-error="El nombre es requerido."
                                                    onChange={updateData}
                                                    value={data.name}
                                                />
                                                <div className="help-block with-errors" />
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="form-group">
                                                <input
                                                    type="email"
                                                    name="mail"
                                                    className="form-control"
                                                    placeholder="alguien@mayofy.com"
                                                    required="required"
                                                    data-error="El correo es requerido."
                                                    onChange={updateData}
                                                    value={data.mail}
                                                />
                                                <div className="help-block with-errors" />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <InputGroup>
                                                <Input
                                                    type="tel"
                                                    name="tel"
                                                    className="form-control"
                                                    placeholder="0123456789"
                                                    required="required"
                                                    data-error="El teléfono es requerido"
                                                    onChange={updateData}
                                                    defaultValue={data.tel}
                                                    disabled={disabled}
                                                />
                                                <InputGroupAddon addonType="append">
                                                    <Button type="button" color="danger" onClick={toggle}>
                                                        Editar numero <i className="las la-pen"></i>
                                                    </Button>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </Col>
                                        <Col md={6}>
                                            <div className="form-group">
                                                <select
                                                    className="form-control"
                                                    name="country"
                                                    onChange={updateData}
                                                    value={data.country}
                                                    disabled={moreInputs}
                                                >
                                                    <option value="MX">Mexico</option>
                                                    <option value="CHN">China</option>
                                                    <option value="AR">Argentina</option>
                                                    <option value="US">United States</option>
                                                </select>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} className="text-center">
                                            <Button type="submit" disabled={moreInputs} color={"success"} >
                                                Actualizar
                                            </Button>
                                        </Col>
                                    </Row>
                                </form>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Modal isOpen={modal} toggle={toggle} className="view-modal">
                <ModalHeader toggle={() => Cancel()}>Validación de número de teléfono</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={12}>
                            <Label>Ingresa el nuevo número de teléfono</Label>
                            <InputGroup>
                                <Input
                                    type="tel"
                                    name="tel"
                                    className="form-control"
                                    placeholder="0123456789"
                                    required="required"
                                    data-error="El teléfono es requerido"
                                    onChange={(e) => { setTelefono(e.target.value) }}
                                    value={telefono}
                                    disabled={ValidNumber}
                                />
                                <InputGroupAddon addonType="append">
                                    <Button type="button" disabled={ValidNumber} color="primary" size="sm" onClick={() => SentCode()} >
                                        Enviar código <i className="las la-paper-plane"></i>
                                    </Button>
                                </InputGroupAddon>
                            </InputGroup>
                        </Col>
                    </Row>
                    <br></br>
                    <Row style={{ disabled: "true" }}>
                        <Col sm={12}>
                            <Label>Ingresa el código que enviamos a tu número celular</Label>
                            <InputGroup>
                                <Input
                                    type="number"
                                    name="code"
                                    className="form-control"
                                    placeholder="01234"
                                    required="required"
                                    data-error="El codigo es requerido"
                                    onChange={updateCode}
                                    value={code}
                                    disabled={ValidCode}
                                />
                                <InputGroupAddon addonType="append">
                                    <Button type="button" disabled={ValidCode} color="danger" size="sm" onClick={() => CheckNumber()} >
                                        Validar código <i className="las la-mobile-alt"></i><i className="las la-check-square"></i>
                                    </Button>
                                </InputGroupAddon>
                            </InputGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={() => Cancel()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </Container>
    );
}

export default React.memo(CustomerDataForm);