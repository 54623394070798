import React from "react";
import { Redirect, Route } from "react-router";

const PrivateRoute = ({ component: Component, ...rest }) => {
    let user = JSON.parse(localStorage.getItem("_user"));
  
    return (
		<Route
			{...rest}
			render={props => {
				if (user) {
                    if(user.id){
                        return <Component {...props} {...rest}/>;
                    }else{
                        return <Redirect to="/login" />;  
                    }
				} else {
					return <Redirect to="/login" />;
				}
			}}
		/>
	);
}

export default PrivateRoute;