import { useState } from "react";
import { Col, Container, Row } from 'reactstrap';
import Pageheading from '../../widgets/pageheading';
import SweetAlert from 'sweetalert2-react';
import axios from "axios";
import { GeneratePasswordRand } from "../../components/generatePasswordRand";
import { useHistory } from 'react-router-dom';

const RecoverPassword = ({ token }) => {

    let NewPasword = GeneratePasswordRand(6, 'alf');
    const [tel, setTel] = useState();
    const data = {
        pass: NewPasword
    };
    const [showMessage, setShowMessage] = useState(false);
    const [type, setType] = useState('success');
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');
    let history = useHistory();
    const goLogin = () => {
        history.push("/login")
    }
    const updateTel = e => {
        setTel(e.target.value);
    }

    const updatePassword = (id) => {
        const url = (window.location.hostname === 'localhost') ? 'http://localhost:1337/customers/' : 'https://backend.mayofy.com/customers7';
        axios({
            method: 'put',
            url: url + parseInt(id),
            data: data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((res) => {
            if (res.status === 200) {
                if (res.data.length < 1 || res.data.error) {
                    setType('error')
                    setTitle('oops')
                    setMessage('Algo salio mal, revisa la información')
                    setShowMessage(true);
                    return;
                }

                sendPassword();
                return;
            } else {
                setType('error')
                setTitle('oops')
                setMessage('Algo salio mal, revisa la información')
                setShowMessage(true);
            }
        }).catch((err) => {
            console.error("error->", err);
            setType('error')
            setTitle('oops')
            setMessage('Algo salio mal, revisa la información')
            setShowMessage(true);
        });
    }

    const sendPassword = () => {
        const url = (window.location.hostname === 'localhost') ? 'http://127.0.0.1:8000/api/sendMessage' : 'https://api.ecommpos.com/api/sendMessage';
        axios({
            method: 'post',
            url: url,
            data: {
                messageContent: NewPasword,
                tel: tel,
                key: process.env.REACT_APP_API_KEY
            }
        }).then((res) => {
            if (res.status === 200) {
                if (res.data.length < 1 || res.data.error) {
                    setType('error')
                    setTitle('oops')
                    setMessage('Algo salio mal, revisa la información')
                    setShowMessage(true);
                    return;
                }

                //console.log("sentMessage->", res.data.message);
                setType('success')
                setTitle('Contraseña  enviado')
                setMessage('Hemos enviado tu nueva contraseña a el número de teléfono: '+tel)
                setShowMessage(true) ;
                goLogin();
                return;
            } else {
                //console.log('res status', res);
                // Show error
                setType('error')
                setTitle('oops')
                setMessage('Algo salio mal, revisa la información')
                setShowMessage(true);
            }
        }).catch((err) => {
            console.log('err', err);

            // Show error
            setType('error')
            setTitle('oops')
            setMessage('Algo salio mal, revisa la información')
            setShowMessage(true);
        })
    }


    /* check if user exists */
    const checkUserExists = () => {
        const url = (window.location.hostname === 'localhost') ? 'http://localhost:1337/customers' : 'https://backend.mayofy.com/customers';
        axios({
            method: 'get',
            url: url,
            params: { tel: tel },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((res) => {
            if (res.status === 200) {
                if (res.data.length < 1) {
                    console.log("error");
                    setType('error')
                    setTitle('oops')
                    setMessage('Algo salio mal, revisa la información')
                    setShowMessage(true);
                    return;
                }
                updatePassword(res.data[0].id);
                return;
            } else {
                setType('error')
                setTitle('oops')
                setMessage('Algo salio mal, revisa la información')
                setShowMessage(true);
            }
        }).catch((err) => {
            setType('error')
            setTitle('oops')
            setMessage('Algo salio mal, revisa la información')
            setShowMessage(true);
        });
    }

    const validateCode = (event) => {
        event.preventDefault();
        checkUserExists();
    }

    return (
        <>
            <SweetAlert
                type={type}
                title={title}
                text={message}
                show={showMessage}
                onConfirm={() => setShowMessage(false)}
            />
            <section className="bg-light page_information">
                <Pageheading foldername={"Pages"} title={"Recuperar contraseña"} />
            </section>
            <div className="page-content">
                <section>
                    <Container>
                        <Row className="justify-content-center">
                            <div className="col-lg-6">
                                <div className="shadow p-3">
                                    <form id="contact-form" method="post" onSubmit={validateCode} style={{ paddingTop: 30 }}>
                                        <Row>
                                            <Col md={6}>
                                                <div className="form-group">
                                                    <input
                                                        id="tel"
                                                        type="tel"
                                                        name="tel"
                                                        className="form-control"
                                                        placeholder="0123456789"
                                                        required="required"
                                                        data-error="Numero de teléfono requerido"

                                                        onChange={updateTel}
                                                        value={tel}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <button type="submit" className="btn btn-primary">
                                                    Recuperar contraseña
                                                </button>
                                            </Col>
                                        </Row>
                                    </form>
                                </div>
                            </div>
                        </Row>
                    </Container>
                </section>
            </div>
        </>
    );
}

export default RecoverPassword;