import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import Pageheading from '../../../../widgets/pageheading';
import { FILTER_PRODUCT, COUNT_PRODUCTS } from '../../../../graphql/queries/products';
import { useLazyQuery } from '@apollo/client';
import ListProducts from '../../../../widgets/shop/listproducts';
import SlideBarCategoires from '../../../../widgets/filter/SlidebarCategory';
import Paginacion from '../../../../components/PaginacionComponent';
import NoData from '../../../../components/NoData';
import { Link } from 'react-router-dom';


function ProductsFilterCategory(props) {
    const { match: { params } } = props;
    const [dataProducts, setdataProducts] = useState({});
    const [count, setCount] = useState(0);
    const ProductParPage = 21;
    const [startValue, setStartValue] = useState(0);
    const [idcategoria, setIdcategoria] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [view, setView] = useState(localStorage.getItem("ProductLayout"));

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const changeView = (new_view) =>{
        if (new_view !== view) {
            setView(new_view);

            localStorage.setItem("ProductLayout", new_view);
        }
    }


    if (params.idCategory !== idcategoria) {
        setStartValue(0);
        setCurrentPage(1);
        setIdcategoria(params.idCategory);
    }

    const [ProductsByCategoria, { loading }] = useLazyQuery(FILTER_PRODUCT, {
        onCompleted: (dataProductsByCat) => {
            setdataProducts(dataProductsByCat.productosConnection.values);
        }

    });
    const [refetch] = useLazyQuery(COUNT_PRODUCTS, {
        onCompleted: (datacount) => {
            setCount(datacount.productosConnection.values.length);
            setTotalPages(Math.ceil(datacount.productosConnection.values.length / ProductParPage));
        }
    });


    useEffect(() => {
        if (params.idCategory !== 'All') {
            ProductsByCategoria({
                variables: {
                    sort: "Nombre:asc",
                    start: startValue,
                    limit: ProductParPage,
                    where: {
                        ImagenPrincipal_ne:null,
                        ArrayImagenes_ne:null,
                        Precio_ne:"",
                        Status: 1,
                        categorias: { id: parseInt(params.idCategory) }
                    }
                }
            });
            refetch({
                variables: {
                    sort: "Nombre:asc",
                    where: {
                        ImagenPrincipal_ne:null,
                        ArrayImagenes_ne:null,
                        Status: 1,
                        categorias: { id: parseInt(params.idCategory) }
                    }
                }
            });
        } else {
            ProductsByCategoria({
                variables: {
                    sort: "Nombre:asc",
                    start: startValue,
                    limit: 21,
                    where: {
                        ImagenPrincipal_ne:null,
                        ArrayImagenes_ne:null,
                        Status: 1,
                    }
                }
            });
            refetch({
                variables: {
                    sort: "Nombre:asc",
                    where: {
                        ImagenPrincipal_ne:null,
                        ArrayImagenes_ne:null,
                        Status: 1
                    }
                }
            });
        }
    }, [ProductsByCategoria, params.idCategory, refetch, startValue]);


    const PreviusProduct = () => {
        if (startValue > 0) {
            let result = startValue - ProductParPage;
            setStartValue(result);
        }
    }

    const NextProduct = () => {
        if (currentPage !== totalPages) {
            let valuetemp = currentPage;
            setStartValue(ProductParPage * valuetemp);
        }
    }

    return (
        <>
            {/*hero section start*/}
            <section className="bg-light page_information">
                <Pageheading foldername={"Compras"} title={params.nombre} />
            </section>
            <div className="page-content">
                <section>
                    <Container>
                        <Row>
                            <>
                                <div className="col-lg-9 col-md-12">
                                    <Row>
                                        <Col md={12}>
                                            <div className="view-filter" style={{ textAlign: 'right' }}>
                                                {view === 'Gridview' ?
                                                    <>
                                                        <Link to="#" className="sky-grid-view active" onClick={() => changeView('Gridview')}>
                                                            <i className="lab la-buromobelexperte" />
                                                        </Link>
                                                        <Link to="#" className="sky-list-view " onClick={() => changeView('Listing')} >
                                                            <i className="las la-list" />
                                                        </Link>
                                                    </>
                                                    :
                                                    <>
                                                        <Link to="#" className="sky-grid-view " onClick={() => changeView('Gridview')}>
                                                            <i className="lab la-buromobelexperte" />
                                                        </Link>
                                                        <Link to="#" className="sky-list-view active" onClick={() => changeView('Listing')} >
                                                            <i className="las la-list" />
                                                        </Link>
                                                    </>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {dataProducts.length !== undefined && dataProducts.map((producto, i) => (
                                            <ListProducts productdata={producto} key={i + "categoria"} countProduct={count} col_lg={4} loading={loading} layout={view} />
                                        ))}
                                        <div className="text-center col-12">

                                            {(dataProducts.length > 0) ?
                                                <Paginacion
                                                    currentPage={currentPage}
                                                    setCurrentPage={setCurrentPage}
                                                    totalPages={totalPages}
                                                    NextProduct={NextProduct}
                                                    PreviusProduct={PreviusProduct}
                                                />
                                                :
                                                <Row className="text-center12">
                                                    <NoData />
                                                </Row>
                                            }
                                        </div>
                                    </Row>
                                </div>
                                <Col lg={3} md={12} className="sidebar mt-8 mt-lg-0">
                                    <SlideBarCategoires />
                                </Col>
                            </>
                        </Row>
                    </Container>
                </section>
            </div>
        </>
    );
}

export default React.memo(ProductsFilterCategory);