import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { InputGroup, InputGroupAddon, Button, Input } from 'reactstrap';

const ToAddShoppingCart = ({ producto }) => {
    const [cantidad, setcantidad] = useState(1);
    let idStore = 0;

    if (producto) {
        if (producto.store !== undefined) {
            idStore = parseInt(producto.store.id);
        }
    }

    const calculate_price_levels = (product,amount)=>{
        let Cart = JSON.parse(localStorage.getItem("CartProduct"));
        if (Cart == null) {
            Cart = new Array(0);
        }

        let existingQuantity = 0;
        let Productadd = Cart.find(productCart => productCart.ProductID === product.id);
       
        if(Productadd !== null && Productadd !== undefined){
            existingQuantity = parseInt(Productadd.Qty);
        }
        
        let amount_to_evaluate = parseInt(amount) + existingQuantity;

        if(product.nivelesDePrecio !== null){
            let nivelesDePrecio = JSON.parse(product.nivelesDePrecio);

            if(nivelesDePrecio.length > 0){
                let numMax = nivelesDePrecio.length;
                for (let index = 0; index < nivelesDePrecio.length; index++) {
                    const element = nivelesDePrecio[index];
                 
                    if(index < numMax){
                       
                        let nextValue = nivelesDePrecio[index+1]
    
                        if(nextValue !== undefined){
                            if(amount_to_evaluate >= parseInt(element.quantity) && amount_to_evaluate < nextValue.quantity && product.Cantidad >= cantidad){
                                return parseFloat(element.price);
                            } 
                        }else{
                            if(amount_to_evaluate >= parseInt(element.quantity) && product.Cantidad >= cantidad ){ 
                                return parseFloat(element.price);
                            } 
                        }      
                    }
                }

                return (product.PrecioOferta !== null && product.PrecioOferta !== 0 ? product.PrecioOferta : product.Precio);
            }
        }

        return (product.PrecioOferta !== null && product.PrecioOferta !== 0 ? product.PrecioOferta : product.Precio);
    }

    const addTocart = (product) => {

        let ratingSales = 0;
        if(product.totalSales !== null){
            ratingSales = parseInt(product.totalSales);
        }

        if (cantidad < 0) {
            toast.error("Ingrese otra cantidad");
            setcantidad(1);
            return;
        }

       /*  let price = (product.PrecioOferta !== null && product.PrecioOferta !== 0 ? product.PrecioOferta : product.Precio); */
        let Cart = JSON.parse(localStorage.getItem("CartProduct"));

        if (Cart == null) {
            Cart = new Array(0);
        }

        let Productadd = Cart.find(productCart => productCart.ProductID === product.id);
        if (Productadd == null && product.Cantidad > 0) {

            if (cantidad > product.Cantidad) {
                /* calculate price if price levels exist */
                let calulate_price =  calculate_price_levels(product,cantidad);
                console.log("price calulate -> ",calulate_price);

                /* agregar la cantidad de stock del producto al carrito de compras */
                Cart.push({ ProductID: product.id, ProductName: product.Nombre, Rate: calulate_price, ProductImage: product.ImagenPrincipal, Qty: product.Cantidad, storeId: idStore, stock: product.Cantidad , ratingSales : ratingSales});
                localStorage.removeItem("CartProduct");
                localStorage.setItem("CartProduct", JSON.stringify(Cart));
                let flag = 0;
                if (flag === 0) {
                    toast.success("Productos agregados al carrito");
                    flag = 1;
                    setcantidad(1);
                }
            } else {
                /* calculate price if price levels exist */
                let calulate_price =  calculate_price_levels(product,cantidad);
                console.log("price calulate -> ",calulate_price);   

                /* agregar la cantidad que ingreso el usuario  */
                Cart.push({ ProductID: product.id, ProductName: product.Nombre, Rate: calulate_price, ProductImage: product.ImagenPrincipal, Qty: cantidad, storeId: idStore, stock: product.Cantidad ,ratingSales : ratingSales});
                localStorage.removeItem("CartProduct");
                localStorage.setItem("CartProduct", JSON.stringify(Cart));
                let flag = 0;
                if (flag === 0) {
                    toast.success("Productos agregados al carrito");
                    flag = 1;
                    setcantidad(1);
                }
            }
        } else {
            let Productadd = Cart.find(productCart => productCart.ProductID === product.id);

            if (Productadd !== null) {
                let CartValue = JSON.parse(localStorage.getItem("CartProduct"));
                for (let index = 0; index < CartValue.length; index++) {
                    const element = CartValue[index];
                    if (element.ProductID === product.id) {
                        let suma = parseInt(element.Qty) + parseInt(cantidad);
                        if (!(suma > product.Cantidad)) {
                            /* calculate price if price levels exist */
                            let calulate_price =  calculate_price_levels(product,cantidad);
                            console.log("price calulate -> ",calulate_price);

                            element.Rate = calulate_price;
                            element.Qty = parseInt(suma);
                            localStorage.removeItem("CartProduct");
                            localStorage.setItem("CartProduct", JSON.stringify(CartValue));
                            toast.success("Productos agregados al carrito");
                            setcantidad(1);
                            return;
                        } else {
                            let qtyAgregar = parseInt(product.Cantidad) - parseInt(element.Qty);
                            if (qtyAgregar > 0) {
                                /* calculate price if price levels exist */
                                let calulate_price =  calculate_price_levels(product,cantidad);
                                console.log("price calulate -> ",calulate_price);
                                element.Rate = calulate_price;
                                element.Qty = parseInt(element.Qty) + parseInt(qtyAgregar);
                                localStorage.removeItem("CartProduct");
                                localStorage.setItem("CartProduct", JSON.stringify(CartValue));
                                toast.success("Solo se agregaron: " + qtyAgregar + "al carrito");
                                setcantidad(1);
                                return;
                            } else {
                                setcantidad(1);
                                toast.warning("No hay stock disponible");
                                return;
                            }
                        }
                    }
                }
            }
        }
    }

    if (producto.Cantidad <= 0) return (
        <Button className="btn btn-primary btn-animated mr-sm-4 mb-3 mb-sm-0"  >
            <i className="las la-exclamation-triangle mr-1"></i> Agotado
        </Button>
    )

    return (
        <InputGroup style={{ width: "50%" }} >
            <Input name="cantidad" type="number" className="form-control" value={cantidad} onChange={(e) => setcantidad(e.target.value)} />
            <InputGroupAddon addonType="append">
                <Button onClick={() => addTocart(producto)} className="btn btn-primary btn-animated mr-sm-4 mb-3 mb-sm-0" ><i className="las la-shopping-cart mr-1" /></Button>
            </InputGroupAddon>
        </InputGroup>
    );
}

export default React.memo(ToAddShoppingCart);
