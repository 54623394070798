import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Input, Card, CardBody, Button, Label, Tooltip } from 'reactstrap';
import { toast } from 'react-toastify';
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";
import axios from 'axios';

const Direction = ({ CloseForm, handleSubmit, dataAddress, action, token, billingInformation }) => {

    let user = JSON.parse(localStorage.getItem("_user"));
    const url = (window.location.hostname === 'localhost') ? 'http://localhost:1337' : 'https://backend.mayofy.com';
    const [invoice, setinvoice] = useState(billingInformation);
    const [refresh, setRefresh] = useState(false);
    const [userId] = useState(user.id ? user.id : 0);
    const [direction, setDirection] = useState({
        address_name: (dataAddress.address_name === undefined ? "" : dataAddress.address_name),
        street: (dataAddress.street === undefined ? "" : dataAddress.street),
        suburb: (dataAddress.suburb === undefined ? "" : dataAddress.suburb),
        country: (dataAddress.country === undefined ? "" : dataAddress.country),
        interior_number: (dataAddress.interior_number === undefined ? "" : dataAddress.interior_number),
        outdoor_number: (dataAddress.outdoor_number === undefined ? "" : dataAddress.outdoor_number),
        postal_code: (dataAddress.postal_code === undefined ? "" : dataAddress.postal_code),
        state: (dataAddress.state === undefined ? "" : dataAddress.state),
        town: (dataAddress.town === undefined ? "" : dataAddress.town),
        comment: (dataAddress.comment === undefined ? "" : dataAddress.comment),
        status: true,
        customer: parseInt(userId)
    });
    const [address, setAddress] = React.useState("");

    const getInvoice = (event) => {
        setinvoice(event.target.checked)
    }

    const handleInputChange = (event) => {

        if (invoice === true) {
            if (event.target.name === "business_name") {
                setDirection({
                    ...direction,
                    [event.target.name]: event.target.value.toUpperCase()
                })
            } else if (event.target.name === "rfc") {
                setDirection({
                    ...direction,
                    [event.target.name]: event.target.value.toUpperCase()
                })
            }
        }

        setDirection({
            ...direction,
            [event.target.name]: event.target.value
        })
    }

    const enviarDatos = (event) => {
        event.preventDefault();
        if (action === "new") {
            axios({
                method: 'post',
                url: url + "/addresses",
                data: direction,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((res) => {
                setRefresh(true);
                CloseForm();
                handleSubmit();
                toast.success("Operación realizada con éxito");
            }).catch((err) => {
                toast.error("Error al realizar operación");
            })
        } else if (action === "update") {
            axios({
                method: 'put',
                url: url + "/addresses/" + parseInt(dataAddress.id),
                data: direction,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((res) => {
                setRefresh(true);
                CloseForm();
                handleSubmit();
                toast.success("Operación realizada con éxito");
            }).catch((err) => {
                toast.error("Error al realizar operación");
            })
        }

        if (refresh === true) {
            event.target.reset();
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const handleSelect = async value => {
        const results = await geocodeByAddress(value);
        const directionObj = results[0].address_components;
        let route = "";
        let street_number = "";
        let neighborhood = "";
        let locality = "";
        let state = "";
        let country = "";
        let postal_code = "";

        for (let index = 0; index < directionObj.length; index++) {
            const element = directionObj[index];
            if (element.types[0] === "route") {
                route = element.long_name;
            } else if (element.types[0] === "street_number") {
                street_number = element.long_name;
            } else if (element.types[0] === "neighborhood") {
                neighborhood = element.long_name
            } else if (element.types[0] === "locality") {
                locality = element.long_name;
            } else if (element.types[0] === "administrative_area_level_1") {
                state = element.long_name;
            } else if (element.types[0] === "country") {
                country = element.long_name;
            } else if (element.types[0] === "postal_code") {
                postal_code = element.long_name;
            }
        }

        setDirection({
            ...direction,
            street: route !== "" ? route : direction.street,
            suburb: neighborhood !== "" ? neighborhood : direction.suburb,
            country: country !== "" ? country : direction.country,
            outdoor_number: street_number !== "" ? street_number : direction.outdoor_number,
            postal_code: postal_code !== "" ? postal_code : direction.postal_code,
            state: state !== "" ? state : direction.state,
            town: locality !== "" ? locality : direction.town,
        });
        setAddress(value);
    };

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);

    return (
        <>
            {/*Address section*/}
            <Container>
                <Row>
                    <Col lg={12} md={12} className="ml-auto mr-auto">
                        <Card>
                            <CardBody>
                                <div className="register-form">
                                    <form id="contact-form" onSubmit={enviarDatos}>
                                        <div className="messages" />
                                        <Row>
                                            <Col lg={12}>
                                                <Label for="">Buscar de dirección<span style={{ textDecoration: "underline", color: "blue" }} href="#" id="DisabledAutoHideMapsInstruction"><i className="las la-question-circle"></i></span></Label>
                                                <Tooltip placement="top" isOpen={tooltipOpen} autohide={false} target="DisabledAutoHideMapsInstruction" toggle={toggle}>
                                                    Selecciona una dirección de las sugerencias
                                                </Tooltip>
                                                <PlacesAutocomplete
                                                    value={address}
                                                    onChange={setAddress}
                                                    onSelect={handleSelect}
                                                >
                                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                        <div>
                                                            <input {...getInputProps({ placeholder: "Buscar dirección " })} className="form-control" />
                                                            <br></br>
                                                            <div>
                                                                {loading ? <div>...loading</div> : null}

                                                                {suggestions.map((suggestion, i) => {
                                                                    const style = {
                                                                        backgroundColor: suggestion.active ? "#41b6e6" : "#fff"
                                                                    };

                                                                    return (
                                                                        <div {...getSuggestionItemProps(suggestion, { style })} key={i}>
                                                                            {suggestion.description}
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    )}
                                                </PlacesAutocomplete>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>
                                                <div className="form-group">
                                                    <Label for="form_name_direction">Nombre de dirección</Label>
                                                    <input id="form_name_direction" type="text" value={direction.address_name} name="address_name" className="form-control" placeholder="Ejem: Departamento" required="required" data-error="Nombre de dirección es requerida." onChange={handleInputChange} />
                                                    <div className="help-block with-errors" />
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div className="form-group">
                                                    <Label for="form_Street">Calle</Label>
                                                    <input id="form_Street" type="text" name="street" value={direction.street} className="form-control" placeholder="Ejem: Calle 18 de julio" required="required" data-error="Calle es requerida." onChange={handleInputChange} />
                                                    <div className="help-block with-errors" />
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div className="form-group">
                                                    <Label for="form_Suburb">Colonia</Label>
                                                    <input id="form_Suburb" type="text" name="suburb" className="form-control" value={direction.suburb} placeholder="Ejem: Colonia Insurgentes" required="required" data-error="Calle es requerida." onChange={handleInputChange} />
                                                    <div className="help-block with-errors" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <br></br>
                                        <Row>
                                            <Col md={4}>
                                                <div className="form-group">
                                                    <Label for="form_outdoor_number" >Numero exterior</Label>
                                                    <input id="form_outdoor_number" type="text" name="outdoor_number" value={direction.outdoor_number} className="form-control" placeholder="Ejem: 34" required="required" data-error="Numero exterior es requerido" onChange={handleInputChange} />
                                                    <div className="help-block with-errors" />
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div className="form-group">
                                                    <Label for="form_interior_number">Numero interior</Label>
                                                    <input id="form_interior_number" type="text" name="interior_number" value={direction.interior_number} className="form-control" placeholder="Ejem: 34-a" data-error="Numero interior es required" onChange={handleInputChange} />
                                                    <div className="help-block with-errors" />
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div className="form-group">
                                                    <Label for="form_postal_code">Código postal</Label>
                                                    <input id="form_postal_code" type="text" name="postal_code" value={direction.postal_code} className="form-control" placeholder="Ejem: 45300" required="required" data-error="Código postal es requerido." onChange={handleInputChange} maxLength="5" minLength="5" />
                                                    <div className="help-block with-errors" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <br></br>
                                        <Row>
                                            <Col md={4}>
                                                <div className="form-group">
                                                    <Label for="form_town">Ciudad</Label>
                                                    <input id="form_town" type="text" name="town" value={direction.town} className="form-control" placeholder="Ejem: ciudad de México" required="required" data-error="Ciudad es requerida." onChange={handleInputChange} />
                                                    <div className="help-block with-errors" />
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div className="form-group">
                                                    <Label for="form_state">Estado</Label>
                                                    <input id="form_state" type="text" name="state" value={direction.state} className="form-control" placeholder="Ejem: Jalisco" required="required" data-error="Estado es requirido." onChange={handleInputChange} />
                                                    <div className="help-block with-errors" />
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div className="form-group"  >
                                                    <Label for="country">Pais</Label>
                                                    <select className="form-control" name="country" value={direction.country} onChange={handleInputChange} required="required" data-error="El pais es requerido.">
                                                        <option value="">Elige uno</option>
                                                        <option value="Mexico">Mexico</option>
                                                        <option value="Chile">Chile</option>
                                                        <option value="China">China</option>
                                                        <option value="Argentina">Argentina</option>
                                                        <option value="Estados Unidos">Estados Unidos</option>
                                                    </select>
                                                    <div className="help-block with-errors" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <br></br>
                                        <Row>
                                            <Col md={12}>
                                                <Label for="form_comment">Comentarios</Label>
                                                <Input type="textarea" name="comment" value={direction.comment} id="form_comment" onChange={handleInputChange} />
                                            </Col>
                                        </Row>
                                        <br></br>
                                        <Row className="mt-5">
                                            <Col md={12}>
                                                <div className="remember-checkbox clearfix mb-5 text-center">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="customCheck1" name="invoice" defaultChecked={invoice} onChange={getInvoice} />
                                                        <label className="custom-control-label" htmlFor="customCheck1">¿Solicitar Facturación para tu pedido?</label>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <br></br>
                                        <Row>
                                            {(invoice === true) ?
                                                <>
                                                    <Col md={4}>
                                                        <div className="form-group">
                                                            <Label for="form_business_name">Razón social</Label>
                                                            <input id="form_business_name" style={{ textTransform: 'uppercase' }} value={direction.business_name} type="text" name="business_name" className="form-control" placeholder="Razón Social" data-error="Razón Social required." onChange={handleInputChange} />
                                                            <div className="help-block with-errors" />
                                                        </div>
                                                    </Col>
                                                    <Col md={4}>
                                                        <div className="form-group">
                                                            <Label for="form_rfc">RFC</Label>
                                                            <input id="form_rfc" style={{ textTransform: 'uppercase' }} type="text" value={direction.rfc} name="rfc" className="form-control" placeholder="rfc" data-error="rfc required." onChange={handleInputChange} />
                                                            <div className="help-block with-errors" />
                                                        </div>
                                                    </Col>
                                                    <Col md={4}>
                                                        <div className="form-group">
                                                            <Label for="form_invoice_address">Dirección</Label>
                                                            <input id="form_Invoicing_address" type="text" value={direction.Invoicing_address} name="Invoicing_address" className="form-control" placeholder="Dirección" data-error="Direccion required." onChange={handleInputChange} />
                                                            <div className="help-block with-errors" />
                                                        </div>
                                                    </Col>
                                                </>
                                                :
                                                <>
                                                </>
                                            }
                                        </Row>
                                        <Row>
                                            <Col md={12} className="text-center">
                                                <Button type="submit" color={"success"} style={{ marginRight: "10px", width: "100px" }}>Registrar</Button>
                                                <Button type="button" color={"warning"} style={{ width: "100px" }} onClick={() => CloseForm()}>Cerrar </Button>
                                            </Col>
                                        </Row>
                                    </form>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {/*body content end*/}
        </>
    );
}

export default React.memo(Direction);