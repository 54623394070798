import React, { useEffect, useState } from 'react'
import axios from 'axios'
import {
  Table,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Card,
  CardBody,
  Spinner
} from 'reactstrap'

const ListSales = ({ token }) => {
  const userdata = JSON.parse(localStorage.getItem('_user'))
  const url =
    window.location.hostname === 'localhost'
      ? 'http://localhost:1337'
      : 'https://backend.mayofy.com'
  const [salesCustomer, setSalesCustomer] = useState({})
  const [sales_products, setsales_products] = useState({})
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)
  const [address, setAddress] = useState({})
  const [NameStores, setNameStores] = useState({});
  const tempNames = [];

  const Details = (id) => {
    if (token) {
      axios({
        method: 'get',
        url: url + '/sales/' + parseInt(id),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          getStoreDetails(res.data.sales_per_vendors)
          setsales_products(res.data.sales_products)
          setAddress(res.data.data.address)
        })
        .catch((err) => {
          console.log('error->', err)
        })
    }
  }

  useEffect(() => {
    if (token) {
      axios({
        method: 'get',
        url: url + '/customers/' + parseInt(userdata.id),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          setSalesCustomer(res.data.sales);
        })
        .catch((err) => {
          console.log('error->', err)
        })
    }
  }, [token, url, userdata.id])

  const SaleDetails = (id) => {
    Details(id);
    document.getElementById(id+"show").style.display = "none";
    document.getElementById(id+"loading").style.display = "inline";

    setTimeout(() => {
      toggle();
      document.getElementById(id+"show").style.display = "inline";
      document.getElementById(id+"loading").style.display = "none";  
    }, 1000);
  }

  const closeModal = () => {
    toggle();
    setsales_products({});
  }

  const getStoreDetails = (data) => {
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      axios({
        method: 'get',
        url: url + '/stores/' + parseInt(element.store),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        let Name = res.data.Nombre;
        tempNames.push(Name);
      })
      .catch((err) => {
        console.log('error->', err);
      })
    
    }
    setNameStores(tempNames);
  }

  return (
    <>
      <Table borderless responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Fecha de compra</th>
            <th>Mensaje</th>
            <th>total</th>
            <th>Detalles</th>
          </tr>
        </thead>
        <tbody>
          {salesCustomer.length !== undefined && salesCustomer.length !== 0 ? (
            <>
              {salesCustomer.length !== undefined &&
                salesCustomer.map((sales, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>
                      {sales.date === null ? '' : sales.date.substring(0, 10)}
                    </td>
                    <td>{sales.message === null ? '' : sales.message}</td>
                    <td>{sales.total === null ? '' : sales.total}</td>
                    <th>
                      <Button
                        id={sales.id+"show"}
                        style={{display : "inline" }}
                        type="button"
                        color="success"
                        size="sm"
                        onClick={() => SaleDetails(sales.id)}
                      >
                        Detalles 
                      </Button>
                      <Button
                        id={sales.id+"loading"}
                        style={{display : "none"}}
                        type="button"
                        color="info"
                        size="sm"
                        onClick={() => SaleDetails(sales.id)}
                      >
                        Cargando... <Spinner style={{ width: '1rem', height: '1rem' }} />{' '}
                      </Button>
                    </th>
                  </tr>
                ))}
            </>
          ) : (
            <>
              <tr key="noDataCustomer" style={{ textAlign: 'center' }}>
                <td colSpan="5"> No hay datos disponibles</td>
              </tr>
            </>
          )}
        </tbody>
      </Table>
      <div>
        <Modal isOpen={modal} toggle={toggle} size="lg">
          <ModalHeader toggle={() => closeModal()}>
            Detalle de compra
          </ModalHeader>
          <ModalBody style={{ width: '100%' }}>
            <Row>
              <Col lg={4}>
                <Table borderless>
                  <thead>
                    <tr>
                      <th>Dirección de envió</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <ListGroup>
                          <ListGroupItem>
                            Nombre de dirección:
                            <br />
                            {address.address_name}{' '}
                          </ListGroupItem>
                          <ListGroupItem>
                            Calle:{' '}
                            {' ' +
                              address.street +
                              ',' +
                              address.outdoor_number}{' '}
                          </ListGroupItem>
                          <ListGroupItem>Ciudad: {address.town}</ListGroupItem>
                          <ListGroupItem>Estado: {address.state}</ListGroupItem>
                          <ListGroupItem>Pais: {address.country}</ListGroupItem>
                          <ListGroupItem>
                            Cp: {address.postal_code}
                          </ListGroupItem>
                        </ListGroup>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
              <Col lg={8}>
                <Table borderless>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Detalle de producto(s)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sales_products.length !== undefined &&
                      sales_products.map((sale, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>
                            <Card>
                              <CardBody>
                                Producto: {sale.name === null ? '' : sale.name}
                                <br />
                                Nombre de tienda: {NameStores[i]}
                                <br />
                                Cantidad:{' '}
                                {sale.quantity === null ? '' : sale.quantity}
                                <br />
                                Precio: {sale.price === null ? '' : '$'+sale.price}
                              </CardBody>
                            </Card>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={() => closeModal()}>
              Cerrar
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  )
}
export default ListSales;
