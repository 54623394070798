import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

import Pageheading from '../../widgets/pageheading';
import classnames from 'classnames';
import CustomerDataForm from './customerDataForm';
import HelperForAddresses from './helperForAddresses';
import ListSales from './listSales';

const MainCustomerData = ({ token }) => {
    const [activeTab, setActiveTab] = useState('1');
    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const closeSession = () => {
        localStorage.removeItem("_user");
    }

    return (
        <>
            <section className="bg-light page_information">
                <Pageheading foldername={"cuenta"} title={"Información de usuario"} />
            </section>
            <section>
                <div className="container-fluid">
                    <div style={{textAlign: 'right'}}>
                        <Link onClick={() => closeSession()} to="/">
                            <i className="las la-power-off"></i> Cerrar sessión
                        </Link>
                    </div>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '1' })}
                                onClick={() => { toggle('1'); }}
                            >
                                Información
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '2' })}
                                onClick={() => { toggle('2'); }}
                            >
                                Direcciones
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '3' })}
                                onClick={() => { toggle('3'); }}
                            >
                                Compras
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <Row>
                                <Col sm="12">
                                    <CustomerDataForm token={token} />
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="2">
                            <HelperForAddresses token={token} checkOut={false} />
                        </TabPane>
                        <TabPane tabId="3">
                            <Row>
                                <ListSales token={token} />
                            </Row>
                        </TabPane>
                    </TabContent>
                </div>
            </section>
        </>
    );
}

export default React.memo(MainCustomerData);