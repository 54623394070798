import React from 'react';
import { Link } from 'react-router-dom';

const StoreLayout = (props) => {
    const logo = '../src/assets/images/logo.png';
    const image = props.image || logo;
    const link = props.link || '#';
    
    return (
        <div className="center mb-4">
            <div className="flex-shrink-0">
                <Link className="center" to={link}>
                    <img className="rounded" src={`${image}`}  alt="Product" width="200" height="100" />
                </Link>
            </div>
        </div>
    );
};

export default StoreLayout;