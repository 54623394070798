import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { FILTER_ADDRESS } from '../../graphql/queries/address';
import { Card, Button, CardTitle, Col, Row, Container } from 'reactstrap';
import GifLoaderComponent from '../../gifLoader/gifloaderComponent';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import axios from 'axios';

const AddressList = ({ flag, AddNewDirection, EditDirection, DeleteDirection, token, checkOut }) => {
    let user = JSON.parse(localStorage.getItem("_user"));
    const url = (window.location.hostname === 'localhost') ? 'http://localhost:1337' : 'https://backend.mayofy.com';
    const [userId] = useState(user.id ? user.id : 0);
    const [dataAddress, setDataAddress] = useState({});
    const { data, loading, refetch } = useQuery(FILTER_ADDRESS, {
        variables: {
            sort: "address_name:asc",
            where: {
                status: true,
                customer: { id: parseInt(userId), token: user.token }
            }
        }
    });

    const logicalEliminated = (id) => {
        axios({
            method: 'put',
            url: url + "/addresses/" + parseInt(id),
            data: { status: false },
            headers: { Authorization: `Bearer ${token}`, },
        }).then((res) => {
            refetch();
            toast.success("Operación realizada con éxito");
        }).catch((err) => {
            toast.error("Error al realizar operación");
        })
    }

    const InformationAddressColumOne = (data) => {
        let informationOneColum = "";
        let street = (data.data.street !== null ? data.data.street + "," : "");
        let outdoor_number = (data.data.outdoor_number !== null ? data.data.outdoor_number : "");
        informationOneColum = street + outdoor_number;

        if (informationOneColum.length > 25) {
            let oneInfoAddress = informationOneColum.substring(0, 25);
            return (
                <>
                    <i className="lar la-circle" />{' '}{oneInfoAddress}{'...'}
                </>
            )
        } else {
            return (
                <>
                    <i className="lar la-circle" />{' '}{informationOneColum}
                </>
            )
        }
    }

    const InformationAddressColumTwo = (data) => {
        let informationTwoColum = "";
        let town = (data.data.town !== null ? data.data.town + "," : "");
        let suburb = (data.data.suburb !== null ? data.data.suburb : "");
        informationTwoColum = town + suburb;

        if (informationTwoColum.length > 25) {
            let twoInfoAddress = informationTwoColum.substring(0, 25);
            return (
                <>
                    <i className="lar la-circle" />{' '}{twoInfoAddress}{'...'}
                </>
            )
        } else {
            return (
                <>
                    <i className="lar la-circle" />{' '}{informationTwoColum}
                </>
            )
        }
    }

    const InformationAddressColumThree = (data) => {
        let informationThreeColum = "";
        let state = (data.data.state !== null ? data.data.state + "," : "");
        let country = (data.data.country !== null ? data.data.country + "," : "");
        let postal_code = (data.data.postal_code !== null ? data.data.postal_code : "")
        informationThreeColum = state + country + postal_code;

        if (informationThreeColum.length > 25) {
            let threeInfoAddress = informationThreeColum.substring(0, 25);
            return (
                <>
                    <i className="lar la-circle" />{' '}{threeInfoAddress}{'...'}
                </>
            )
        } else {
            return (
                <>
                    <i className="lar la-circle" />{' '}{informationThreeColum}
                </>
            )
        }
    }

    let history = useHistory();
    const selectAddress = (address) => {
        console.log('address', address);

        localStorage.setItem('_address', JSON.stringify(address))

        history.push("/summary");
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        if (data) {
            setDataAddress(data.addresses);
        }

        if (flag === true) {
            refetch();
        }
    }, [data, flag, refetch]);

    if (loading) return <Col xl={12} lg={12} md={12} key={"loadingListAddress"} className="text-center"><GifLoaderComponent /></Col>;

    return (
        <Container>
            <Row>
                {dataAddress.length !== undefined && dataAddress.map((address, i) => (
                    <Col xl={3} lg={4} md={6} key={i}>
                        <Card body>
                            <CardTitle tag="h5">
                                {(address.address_name.replace !== null)
                                    ?
                                    (address.address_name.length > 19) ?
                                        address.address_name.substring(0, 18) + "..."
                                        :
                                        (address.address_name)
                                    :
                                    <>
                                    </>
                                }
                            </CardTitle>
                            <div className="mb-1 mt-1">

                                <InformationAddressColumOne data={address} /><br />
                                <InformationAddressColumTwo data={address} /><br />
                                <InformationAddressColumThree data={address} /><br />

                            </div>
                            <Row>
                                <Col md={12}>
                                    <Button color="success" style={{ display: checkOut ? 'inline' : 'none' }} size="sm" onClick={() => selectAddress(address)} block>
                                        <i className="las la-truck"></i> Enviar aquí
                                    </Button>
                                </Col>
                            </Row><br />
                            <div className="text-center">
                                <Button color="warning" size="sm" onClick={() => EditDirection(address)}>
                                    <i className="las la-pencil-alt"></i>
                                </Button>{' '}
                                <Button color="danger" size="sm" type="submit" onClick={() => logicalEliminated(address.id)}>
                                    <i className="las la-trash"></i>
                                </Button>{' '}
                            </div>
                        </Card>
                    </Col>
                ))}

                <Button color="link" onClick={() => AddNewDirection()}>
                    Agregar nueva dirección
                </Button>

            </Row>
        </Container>
    );
}

export default React.memo(AddressList);