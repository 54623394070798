import React from 'react';
import { Link } from 'react-router-dom';
import {
    Col,
    Container,
    Row,
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    Modal,
    ModalHeader,
    ModalBody,
    ListGroup, 
    ListGroupItem
} from 'reactstrap'
import HeaderLogoMenu from './siteheader/headerLogo';
import { useState, useEffect } from 'react';
import { FILTER_BY_STATUS } from '../../graphql/queries/categories';
import { useQuery } from '@apollo/client';
import { ToastContainer } from 'react-toastify';
import GetInfoUser from '../../pages/account/SignOff';

function HeaderPage({keyPage}) {
    const { error, loading, data } = useQuery(FILTER_BY_STATUS, {
        variables: {
            Status: 0
        }
    });

    const [categories, setcategories] = useState({});

    const [flags, setflags] = useState({
        isOpen: false,
        cartview: false,
        loader: true
    })

    const [scrolled, setScrolled] = useState();

    useEffect(() => {
        localStorage.setItem("ProductLayout", 'Gridview');
        if (data) {
            setcategories(data.categoriasConnection.values);
        }
    }, [data]);

    //place static header when scroll is scrolled down
    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 1) {
                setScrolled(true)
            } else {
                setScrolled(false)
            }
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const GetCartItems = () => {
        return JSON.parse(localStorage.getItem("CartProduct"));
    }

    const RemoveItem = (Index) => {
        var CartValue = JSON.parse(localStorage.getItem("CartProduct"));
        CartValue = CartValue.slice(0, Index).concat(CartValue.slice(Index + 1, CartValue.length));
        localStorage.removeItem("CartProduct");
        localStorage.setItem("CartProduct", JSON.stringify(CartValue));
    }

    const Cartview = () => {
        //the value of cartview is updated
        setflags({ ...flags, cartview: !flags.cartview });
    }

    const toggle = () => {
        //the value of isOpen is updated
        setflags({ ...flags, isOpen: !flags.isOpen });
    }

    const handleClick = (event) => {
        var elems = document.querySelectorAll(".childsubmenu");
        [].forEach.call(elems, function (el) {
            el.classList.remove("show");
        });
        showGif();
    }

    const showGif = () => {
        setflags({ ...flags, loader: true });
        toggle();
    }

    const AllCategories = () => {
        if (error) return <h4 key={"categreslistError"+keyPage}>Error</h4>;
        if (loading) return <h4 key={"categreslistLoading"+keyPage}>Loading</h4>;
        return (
            <ul className="list-unstyled">
                <li key={`categoryAll`+keyPage}>
                    <Link key={keyPage+"allCategories"} to={`/productsCategories`} onClick={handleClick}>Todas</Link>
                </li>
                {categories.length !== undefined && categories.map((categoria, i) => (
                    <li key={`category_${i}`+keyPage}>
                        <Link key={`linkcat_${i}`+keyPage} to={`/category/${categoria.Nombre}/${categoria.id}`} onClick={handleClick}>
                            {categoria.Nombre}
                        </Link>
                    </li>
                ))}
            </ul>
        );
    }

    return (
        <>
            <header className="site-header header_color">
                <HeaderLogoMenu keyPage={keyPage} />
                <ToastContainer autoClose={900} />
                <div id="header-wrap" className={`${(scrolled) ? "shadow-sm fixed-header " : "shadow-sm"}`} >
                    <Container>
                        <Row>
                            <Col>
                                <Navbar className="navbar-expand-lg navbar-light position-static">
                                    <Link className="navbar-brand logo d-lg-none" to="/">
                                        <img className="img-fluid"style={{maxWidth:"80%"}} src={require(`../../assets/images/logo_mayofy_v_3.png`).default} alt="" />
                                    </Link>
                                    <NavbarToggler key={"obilMenu"+keyPage} onClick={() => toggle()} />
                                    <Collapse isOpen={flags.isOpen} className="navbar-collapse" navbar>
                                        <Nav className="navbar-nav" navbar>
                                            {scrolled === true  ?
                                                <NavItem className="pcDevice" key={"Logoheader"+keyPage} onClick={() => showGif()}>
                                                    <img className="img-fluid" src={require(`../../assets/images/logo_mayofy_v_3.png`).default} alt="" width="100" height="30" />
                                                </NavItem>
                                                :
                                                <>
                                                </>
                                            }
                                            <NavItem key={"Homeheader"+keyPage} onClick={() => showGif()}>
                                                <Link to="/" className="nav-link">INICIO</Link>
                                            </NavItem>
                                            <UncontrolledDropdown nav inNavbar key={"categoriesHeader"+keyPage} >
                                                <DropdownToggle nav caret >
                                                    CATEGORÍAS
                                                    </DropdownToggle>
                                                <DropdownMenu id={`submenu_1`} className="childsubmenu" >
                                                    <Row className="w-100 no-gutters">
                                                        <div className="container p-0">
                                                            <AllCategories />
                                                        </div>
                                                    </Row>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                            <NavItem key={"Storesheader"+keyPage} onClick={() => showGif()}>
                                                <Link to="/stores" className="nav-link">TIENDAS</Link>
                                            </NavItem>
                                            <NavItem key={"Promotionsheader"+keyPage} onClick={() => showGif()}>
                                                <Link to="/offers" className="nav-link">PROMOCIONES</Link>
                                            </NavItem>
                                            <NavItem key={"Contactheader"+keyPage} onClick={() => showGif()}>
                                                <Link to="/contact" className="nav-link">CONTACTO</Link>
                                            </NavItem>
                                        </Nav>
                                    </Collapse>
                                    <div className="right-nav align-items-center d-flex justify-content-end">
                                        <Link key={"userInfoHeader"+keyPage} className="mr-1 mr-sm-3" to="/customer"><i className="las la-user-alt" /></Link>
                                        {/*<Link key={"userwishlistHeader"+keyPage}className="mr-3 d-none d-sm-inline" to="/wishlist"><i className="lar la-heart" /></Link>*/}
                                        <div>
                                            <Link key={keyPage+"userdatashop"} className="d-flex align-items-center" to="#" id="header-cart-btn" onClick={()=>Cartview()} >
                                                {(GetCartItems() != null && GetCartItems().length > 0) ?
                                                    <>
                                                        <span key={"cartMenuItems"+keyPage} className=" px-2 py-1 shadow-sm rounded" data-cart-items={GetCartItems().length}>
                                                            <i className="las la-shopping-cart" />
                                                        </span>
                                                        <div className="ml-4 d-none d-md-block" >
                                                            <span className="text-dark">
                                                                {GetCartItems().length} Articulo(s) - ${GetCartItems().reduce((fr, CartItem) => fr + (CartItem.Qty * CartItem.Rate), 0).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}
                                                            </span>
                                                        </div>
                                                    </>
                                                    :
                                                    <span className=" px-2 py-1 shadow-sm rounded" data-cart-items={0}>
                                                        <i className="las la-shopping-cart" />
                                                    </span>}

                                            </Link>
                                        </div>
                                        <GetInfoUser />
                                    </div>
                                </Navbar>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </header>
            <Modal isOpen={flags.cartview} toggle={Cartview} className="cart-modal" >
                <ModalHeader toggle={() => Cartview()} >
                {(GetCartItems() !== null && GetCartItems().length > 0) ?
                 <>
                 Su carrito ({GetCartItems().length})
                 </>
                :
                <>
                Su carrito (0)
                </>
                } 
                </ModalHeader>
                <ModalBody >
                    {(GetCartItems() !== null && GetCartItems().length > 0) ?
                        <ListGroup>
                            {GetCartItems().map((CartItem, index) => (
                                <ListGroupItem key={index} >
                                    <div>
                                        <div className="row align-items-center" >
                                            <div className="col-5 d-flex align-items-center" >
                                                <div className="mr-4" >
                                                    <Link to="#" type="submit" className="btn btn-primary btn-sm" onClick={() => RemoveItem(index)}><i className="las la-times" key={"times"+index} />
                                                    </Link>
                                                </div>
                                                {/* Image */}
                                                <a href="product-left-image.html" >
                                                    <img  className="img-fluid" src={`${CartItem.ProductImage}`} alt="..." style={{ height: '90px' ,width:'90px' }} />
                                                </a>
                                            </div>
                                            <div className="col-7" key={"test32_"+index+keyPage}>
                                                {/* Title */}
                                                <h6 >
                                                    <a className="link-title" href="product-left-image.html" key={"keryer_"+index+keyPage}>{CartItem.ProductName}</a>
                                                </h6>
                                                <div className="product-meta" key={"productdaa"+index+keyPage}>
                                                    <span className="mr-2 text-primary" >${(CartItem.Rate * CartItem.Qty).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</span>
                                                    <span className="text-muted" >x {CartItem.Qty}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="my-5" key={"hrcar_"+keyPage+index} />
                                </ListGroupItem>
                            ))}
                            <br></br>
                            <div  className="d-flex justify-content-between align-items-center mb-8">
                                <span className="text-muted">Subtotal:</span>  <span className="text-dark">${GetCartItems().reduce((fr, CartItem) => fr + (CartItem.Qty * CartItem.Rate), 0).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</span>
                            </div>
                            <Link  to="/cart" className="btn btn-primary btn-animated mr-2" onClick={() => Cartview()}>
                                <i className="las la-shopping-cart mr-1" />Ver carrito
                            </Link>
                            <Link  to="/direction?checkout=1" className="btn btn-dark btn-animated mr-2" onClick={() => Cartview()}>
                                <i className="las la-money-check mr-1" />Terminar compra
                            </Link>
                        </ListGroup>
                        :
                        <div key={keyPage+"nodatacart"}>
                            <div className="row align-items-center">
                                <h3 className="mb-4">Su carrito está vacío.</h3>
                            </div>
                        </div>
                    }
                </ModalBody>
            </Modal>
        </>
    );
}

export default HeaderPage;