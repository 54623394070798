import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import SweetAlert from 'sweetalert2-react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import Pageheading from '../../widgets/pageheading';

const PhoneNumber = ({token,setValidPhone, setPhoneNumber,setPhoneNumberCheck }) => {
	/* Hooks
	======================================== */
	const [showMessage, setShowMessage] = useState(false)
	const [showCode, setShowCode] = useState(false)
	const [type, setType] = useState('success')
	const [message, setMessage] = useState('')
	const [apiCode, setApiCode] = useState('')
	const [title, setTitle] = useState('')
	const [code, setCode] = useState('')
	const [tel, setTel] = useState('')

	/* Functions
	======================================== */

	// Functions that ejecute when the user write an input
	const updateTel = e => {
		setTel(e.target.value);
		setPhoneNumberCheck(e.target.value);
	}
	const updateCode = e => {
		setCode(e.target.value);
	}

	// Validate phone number
	const isValid = (p) => {
		var phoneRe = /^[2-9]\d{2}[2-9]\d{2}\d{4}$/;
		var digits = p.replace(/\D/g, "");
		return phoneRe.test(digits);
	}

	/*the user is searched in the database */
	const Userexists =()=>{
		const url = (window.location.hostname === 'localhost') ? 'http://localhost:1337/customers' : 'https://backend.mayofy.com/customers';

        axios({
            method: 'get',
            url: url,
            params: {tel:tel},
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((res) => {
            if (res.status === 200) {
				console.log(res);
                if (res.data.length < 1) {
					sendCode();
					setShowCode(true);
					return;
                }

				setType('info')
				setTitle('oops')
				setMessage('El numero ya está registrado intente con otro número de teléfono.')
				setShowMessage(true)
                return;
            } else {
                console.log('res', res);
            }
        }).catch((err) => {
            console.log('err', err);
        })
	}

	// API - Send code
	const sendCode = () => {
		const url = (window.location.hostname === 'localhost') ? 'http://127.0.0.1:8000/api/sendCode' : 'https://api.ecommpos.com/api/sendCode';
		axios({
			method: 'post',
			url: url,
			data: {
				tel: tel,
				key: process.env.REACT_APP_API_KEY
			}
		}).then((res) => {
			if (res.status === 200) {
				if (res.data.length < 1 || res.data.error) {
					setType('info')
					setTitle('oops')
					setMessage('No se genero el código, intente mas tarde')
					setShowMessage(true)

					console.log('res', res);

					return;
				}

				setApiCode(res.data.code);

				// Show alert
				setType('info')
				setTitle('Código enviado')
				setMessage('Hemos enviado un código de verificación al numero: '+tel)
				setShowMessage(true)
			} else {
				console.log('res status', res);

				// Show error
				setType('error')
				setTitle('oops')
				setMessage('Algo salio mal, revisa la información')
				setShowMessage(true)
			}
		}).catch((err) => {
			console.log('err', err);

			// Show error
			setType('error')
			setTitle('oops')
			setMessage('Algo salio mal, revisa la información')
			setShowMessage(true)
		})
	}

	// Validate phone number
	const validateTel = (e) => {
		e.preventDefault();

		console.log('tel', tel);

		if (!isValid(tel)) {
			setType('info')
			setTitle('oops')
			setMessage('Numero de teléfono no valido')
			setShowMessage(true)

			return;
		}

		// Send code
		Userexists();
	}
	
	// Validate code
	const validateCode = (e) => {
		e.preventDefault();

		if (parseInt(code) !== apiCode) {
			setType('info')
			setTitle('oops')
			setMessage('Código no valido')
			setShowMessage(true)

			return;
		}

		// Set phone number and show sing up form
		setPhoneNumber(tel);
		setValidPhone(true);
	}
	
	return (
		<>
			<SweetAlert
				type={type}
				title={title}
				text={message}
				show={showMessage}
				onConfirm={() => setShowMessage(false)}
			/>
			<section className="bg-light page_information">
				<Pageheading foldername={"Pages"} title={"Número de teléfono"} />
			</section>
			<div className="page-content">
				<section>
					<Container>
						<Row className="justify-content-center">
							<div className="col-lg-6">
								<div className="shadow p-3">
									{showCode ?
										<form id="contact-form" method="post" onSubmit={validateCode} style={{ paddingTop: 30 }}>
											<Row>
												<Col md={8}>
													<div className="form-group">
														<input
															id="code"
															type="number"
															name="code"
															className="form-control"
															placeholder="01234"
															required="required"
															data-error="Numero de teléfono requerido"

															onChange={updateCode}
															value={code}
														/>
													</div>
												</Col>
												<Col md={4}>
													<button type="submit" className="btn btn-primary">
														Confirmar
													</button>
												</Col>
											</Row>
										</form>
										:
										<form id="contact-form" method="post" onSubmit={validateTel} style={{ paddingTop: 30 }}>
											<Row>
												<Col md={8}>
													<div className="form-group">
														<input
															id="tel"
															type="tel"
															name="tel"
															className="form-control"
															placeholder="0123456789"
															required="required"
															data-error="Numero de teléfono requerido"

															onChange={updateTel}
															value={tel}
														/>
													</div>
												</Col>
												<Col md={4}>
													<button type="submit" className="btn btn-primary">
														Validar
													</button>
												</Col>
											</Row>
										</form>
									}
									<p className="small">Al registrarse e ingresar su correo o teléfono, acepta ser contactado por estos medios. Se aplican tarifas de mensajes y datos.</p>
									<Row>
										<Col md={12}>
											<span className="mt-4 d-block">
												¿Ya tienes cuenta? <Link to="/login"><i>Inicia sesión</i></Link>
											</span>
										</Col>
									</Row>
								</div>
							</div>
						</Row>
					</Container>
				</section>
			</div>
		</>
	);
}

export default PhoneNumber;