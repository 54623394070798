import React,{Fragment,Suspense} from 'react';
import {Switch,Route} from 'react-router-dom';
import Footer from './layout/footer/footer';
import HeaderPage from './layout/header/headerPage';
import Herosection from './widgets/herosection/herosection';

import './App.css';
import './vendor.js';

import PrivateRoute from './PrivateRoute';

import ProductsFilterCategory from './pages/shop/layout/grid/productsFilter';
import ProductSearchByIdName from './pages/productSearch/ProductSearch';
import ProductsCategories from './pages/shop/product/productsCategories';
import ProductsDetails from './pages/shop/product/productDetails';
import MainCustomerData from './pages/account/mainCustomerData';
import HomeDirection from './pages/account/homeAddressPage';
import StoreDetails from './pages/stores/storeDetails';
import OrderComplete from './pages/shop/orderComplete';
import MainStores from './pages/stores/MainStores';
import Contact from './pages/contact/contact';
import SignUp from './pages/account/SignUp';
import Offers from './pages/offers/offers';
import Summary from './pages/shop/Summary'
import Login from './pages/account/Login';
import GetToken from './components/Admin'
import Cart from './pages/shop/cart';
import RecoverPassword from './pages/account/recoverPassword';

const Home = React.lazy(() => import('./pages/home/home'));

function App(props) {
    return (
        <Fragment> 
            <div className="page-wrapper">
                <Suspense fallback={<div id="loadingHome"><HeaderPage keyPage={"loadingpage"} /><Herosection /><Footer /></div>}>
                    <HeaderPage keyPage={"HomePage"} />
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route path={`/category/:nombre/:idCategory`} component={ProductsFilterCategory} exact={true} />
                        <Route path={`/productDetails/:productId`} component={ProductsDetails}  exact={true} />
                        <Route path={`/productsCategories`} component={ProductsCategories} exact={true} />
                        <Route path={`/offers`} component={Offers} exact={true} />
                        <Route path={`/stores/details/:id`} component={StoreDetails} exact={true} />
                        <Route path={`/stores`} component={MainStores} exact={true} />
                        <Route path={`/contact`} component={Contact} exact={true} />
                        <Route path={`/cart`} component={Cart} exact={true} />
                        <Route path={`/sign-up`} component={() => <SignUp token={GetToken()} />} />
                        <Route path={`/login`} component={() => <Login token={GetToken()} />} />
                        <Route path={`/forgot-password`} component={() => <RecoverPassword token={GetToken()} exact={true} />} />
                        <Route path={'/:id/:word'} component={ProductSearchByIdName} exact={true} />
                    </Switch>
                    <PrivateRoute path={`/OrderComplete`} component={() => <OrderComplete token={GetToken()} />} exact={true} />
                    <PrivateRoute path={`/customer`}  component={() => <MainCustomerData token={GetToken()} exact={true} />} />
                    <PrivateRoute path={`/direction`} component={() => <HomeDirection token={GetToken()} />} exact={true} />
                    <PrivateRoute path={`/summary`} component={() => <Summary token={GetToken()} />} exact={true} />
                    <Footer />
                </Suspense>
            </div>
        </Fragment>
    );
}

export default App;