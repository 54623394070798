import React from 'react';

import { Link } from 'react-router-dom';

const GetInfoUser =()=>{

    let User = localStorage.getItem("_user");
    let infoUser  = "";

    if(User !== null){
        infoUser = JSON.parse(User);
    }

    const closeSession =()=>{
        localStorage.removeItem("_user");
    }

    return(
        <>
        {User === null  ?
            <>
            </>
            :
            <Link  className="d-flex align-items-center" onClick={()=>closeSession()} to="/">
                <span className="ml-12 d-none d-md-inline text-dark" >{infoUser.mail}</span> <i className="las la-power-off"></i>
            </Link>
        } 
        </>
    )
}

export default GetInfoUser;