import {useState} from 'react';
import {FILTER_ADDRESS} from '../graphql/queries/address';
import {useQuery} from '@apollo/client';

export const CountAddress =()=>{
    const user = JSON.parse(localStorage.getItem("_user"));
    const [userId] = useState(user.id ? user.id : 0);
    const {data ,error} = useQuery(FILTER_ADDRESS,{
        variables:{
            input:{
                sort:"address_name:asc",
                where: {
                   status: true,
                   customer:{id:parseInt(userId),token:user.token}
                }
            }
        }
    });

    if(error) return -1;
    if(data) return (data.addresses.length);
}
