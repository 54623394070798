import React from 'react';
import { Col, Container, Row,Badge} from 'reactstrap';
import { Link } from 'react-router-dom';
import Pageheading from '../../widgets/pageheading';
import { toast } from 'react-toastify';

const cart = () => {
  
  const GetCartItems = () => {
    return JSON.parse(localStorage.getItem("CartProduct"));
  }
 
  const RemoveItem = (Index) => {
    var CartValue = JSON.parse(localStorage.getItem("CartProduct"));
    CartValue = CartValue.slice(0, Index).concat(CartValue.slice(Index + 1, CartValue.length));
    localStorage.removeItem("CartProduct");
    localStorage.setItem("CartProduct", JSON.stringify(CartValue));
  }

  const AddQty = (item,Index) => {
    var CartValue = JSON.parse(localStorage.getItem("CartProduct"));
    CartValue[Index].Qty = parseInt(CartValue[Index].Qty + 1);
    if(parseInt(CartValue[Index].Qty) > parseInt(item.stock)){
      toast.error("Has alcanzado el límite de stock disponible");
      return;
    }
    localStorage.removeItem("CartProduct");
    localStorage.setItem("CartProduct", JSON.stringify(CartValue));
  }

  const RemoveQty = (Index) => {
    var CartValue = JSON.parse(localStorage.getItem("CartProduct"));

    if (CartValue[Index].Qty !== 1) {

      CartValue[Index].Qty = parseInt(CartValue[Index].Qty - 1);
      localStorage.removeItem("CartProduct");
      localStorage.setItem("CartProduct", JSON.stringify(CartValue));
    } else {
      RemoveItem(Index);
    }
  }

  return (
    <>
      {/*hero section start*/}
      <section className="bg-light page_information">
        <Pageheading foldername={"Shop"} title={"Product Cart"} />
      </section>
      {/*hero section end*/}
      {/*body content start*/}
      <div className="page-content">
        <section>
          <Container>
            {(GetCartItems() !== null && GetCartItems().length > 0) ?
              <Row>
                <div className="col-lg-8">
                  <div className="table-responsive">
                    <table className="cart-table table">
                      <thead>
                        <tr>
                          <th scope="col">Producto</th>
                          <th scope="col">Precio</th>
                          <th scope="col">Cantidad</th>
                          <th scope="col">Total</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                      
                        {GetCartItems().map((CartItem, index) => (
                         
                          <tr key={index}>
                            <td>
                              <div className="media align-items-center">
                                <Link to="#">
                                  <img className="img-fluid" src={`${CartItem.ProductImage}`} style={{ height: '100px' ,width:'100px' }} alt="" />
                                </Link>
                                <div className="media-body ml-3">
                                  <div className="product-title mb-2"><Link className="link-title" to="#">{CartItem.ProductName}</Link>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td> <span className="product-price text-muted">
                              ${CartItem.Rate.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}
                            </span>
                            </td>
                            <td>
                              <div className="d-flex justify-content-center align-items-center">
                                <Link to="#" className="btn btn-primary btn-product-up btn-sm" onClick={() => RemoveQty(index)}> <i className="las la-minus" />
                                </Link>
                                  <h5> <Badge color="light">{CartItem.Qty}</Badge></h5>
                                <Link to="#" className="btn btn-primary btn-product-down btn-sm" onClick={() => AddQty(CartItem,index)}> <i className="las la-plus" />
                                </Link>
                              </div>
                            </td>
                            <td>
                              <span className="product-price text-dark font-w-6">
                                ${(CartItem.Rate * CartItem.Qty).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}
                              </span>
                            </td>
                            <td>
                              <Link 
                                to="#" 
                                type="submit" 
                                className="btn btn-primary btn-sm" 
                                onClick={
                                  () => RemoveItem(index)
                                  }
                                >
                                  <i className="las la-times" />
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-lg-4 pl-lg-5 mt-8 mt-lg-0">
                  <div className="shadow rounded p-5">
                    <h4 className="text-black text-center mb-2">Total del carrito</h4>
                    <div className="d-flex justify-content-between align-items-center border-bottom py-3"> <span className="text-muted">Subtotal</span>  <span className="text-dark">${GetCartItems().reduce((fr, CartItem) => fr + (CartItem.Qty * CartItem.Rate), 0).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center border-bottom py-3"> <span className="text-muted">Tax</span>  <span className="text-dark">$00.00</span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center pt-3 mb-5"> <span className="text-dark h5">Total</span>  <span className="text-dark font-weight-bold h5">${GetCartItems().reduce((fr, CartItem) => fr + (CartItem.Qty * CartItem.Rate), 0).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</span>
                    </div>
                    <Link className="btn btn-primary btn-animated btn-block" to="/direction?checkout=1">Proceder a pago</Link>
                    <Link className="btn btn-dark btn-animated btn-block mt-3" to="/productsCategories">Continuar comprando</Link>
                  </div>
                </div>
              </Row>
              :
              <Row>
                <Col md={12} className="text-center pb-11">
                  <h3 className="mb-4">Su carrito está vacío.</h3>
                  <Link className="btn btn-primary mr-3" to="/">Inicio</Link>
                  <Link className="btn btn-primary" to="/productsCategories">Continuar comprando</Link>
                </Col>
              </Row>
            }
          </Container>
        </section>
      </div>
      {/*body content end*/}
    </>
  );

}

export default React.memo(cart);