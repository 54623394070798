import { useState, useEffect } from 'react'

import axios from 'axios';

const GetToken = () => {
	const [token, setToken] = useState(null)

	const getToken = () => {
		// console.log('Token:', token);
		return token
	}

	useEffect(() => {
		if (!token) {
			const url = (window.location.hostname === 'localhost') ? 'http://localhost:1337/' : 'https://backend.mayofy.com/';
			axios.post(url+'auth/local', {
				identifier: process.env.REACT_APP_FRONT_USER,
				password: process.env.REACT_APP_FRONT_PASS
			}).then(response => {
				setToken(response.data.jwt)
			}).catch(error => {
				console.log('An error occurred:', error);
			});
		}
	}, [token])

	return getToken();
}

export default GetToken;