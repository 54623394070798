import React, { useState } from 'react'
import { Card, CardBody, CardText, Button, CardTitle, Col, Row, Container } from 'reactstrap';
import SweetAlert from 'sweetalert2-react';
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios';

const Summary = ({ token }) => {
	const url_strapi = (window.location.hostname === 'localhost') ? 'http://localhost:1337/' : 'https://backend.mayofy.com/';
	const url_api = (window.location.hostname === 'localhost') ? 'http://127.0.0.1:8000/api/' : 'https://api.ecommpos.com/api/';

	let total_products = 0
	let total_success = 0
	let successData = {
		sales_per_vendor: [],
		sales_products: null,
		main_sale: null,
	}

	let history = useHistory();

	/* Hooks
	======================================== */
	const [payment, setPayment] = useState('upon_delivery')
	const [showMessage, setShowMessage] = useState(false)
	const [type, setType] = useState('success')
	const [message, setMessage] = useState('')
	const [title, setTitle] = useState('')

	/* Functions
	======================================== */
	const GetCartItems = () => {
		return JSON.parse(localStorage.getItem("CartProduct"));
	}

	const InformationAddressColumOne = (data) => {
		let informationOneColum = "";
		let street = (data.data.street !== null ? data.data.street + ", " : "");
		let outdoor_number = (data.data.outdoor_number !== null ? data.data.outdoor_number : "");
		informationOneColum = street + outdoor_number;

		return informationOneColum
	}

	const InformationAddressColumTwo = (data) => {
		let informationTwoColum = "";
		let town = (data.data.town !== null ? data.data.town + ", " : "");
		let suburb = (data.data.suburb !== null ? data.data.suburb : "");
		informationTwoColum = town + suburb;

		return informationTwoColum
	}

	const InformationAddressColumThree = (data) => {
		let informationThreeColum = "";
		let state = (data.data.state !== null ? data.data.state + ", " : "");
		let country = (data.data.country !== null ? data.data.country + ", " : "");
		let postal_code = (data.data.postal_code !== null ? data.data.postal_code : "")
		informationThreeColum = state + country + postal_code;

		return informationThreeColum
	}

	// API / Strapi - Main sale
	const mainSale = (data) => {
		axios({
			method: 'post',
			url: url_strapi + 'sales',
			data: data.main_sale,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}).then((res) => {
			if (res.status === 200) {
				if (res.data.length < 1) {
					setType('info')
					setTitle('oops')
					setMessage('Mail o contraseña incorrectos')
					setShowMessage(true)

					return;
				}

				// Save on global sale data
				data.main_sale.id = res.data.id
				successData.main_sale = data.main_sale;

				// Update father with main sale id
				const sales_per_vendor = Object.values(data.sales_per_vendor).reduce(function (obj, value, index) {
					value.father = res.data.id
					obj[index] = value

					return obj
				}, {});

				// Create sales per vendor on API
				Object.values(sales_per_vendor).forEach(sale => {
					salesPerVendor(sale)
				})
			} else {
				console.log('res', res);

				// Show error
				setType('error')
				setTitle('oops')
				setMessage('Algo salio mal, revisa la información')
				setShowMessage(true)
			}
		}).catch((err) => {
			console.log('err', err);

			// Show error
			setType('error')
			setTitle('oops')
			setMessage('Algo salio mal, revisa la información')
			setShowMessage(true)
		})
	}

	// API / Strapi - Sales per vendor
	const salesPerVendor = (data) => {
		axios({
			method: 'post',
			url: url_strapi + 'sales-per-vendors',
			data: data,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}).then((res) => {
			if (res.status === 200) {
				if (res.data.length < 1) {
					setType('info')
					setTitle('oops')
					setMessage('Ocurrió un error al guardar la venta - salesPerVendor')
					setShowMessage(true)

					console.log('salesPerVendor - res', res);

					return;
				}

				// Save on global sale data
				data.id = res.data.id
				successData.sales_per_vendor.push(data)

				// Update sale with sale id
				const sales_products = Object.values(data.data.products).reduce(function (obj, value, index) {
					value.main_sale = data.father
					value.sale = res.data.id
					obj[index] = value

					return obj
				}, {});

				Object.values(sales_products).forEach(product => {
					salesProducts(product)
				})
			} else {
				console.log('res', res);

				// Show error
				setType('error')
				setTitle('oops')
				setMessage('Algo salio mal, revisa la información')
				setShowMessage(true)
			}
		}).catch((err) => {
			console.log('err', err);

			// Show error
			setType('error')
			setTitle('oops')
			setMessage('Algo salio mal, revisa la información')
			setShowMessage(true)
		})
	}

	// API / Strapi - Sales products
	const salesProducts = (data) => {
		axios({
			method: 'post',
			url: url_strapi + 'sales-products',
			data: data,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}).then((res) => {
			if (res.status === 200) {
				if (res.data.length < 1) {
					setType('info')
					setTitle('oops')
					setMessage('Ocurrió un error al guardar la venta - salesProducts')
					setShowMessage(true)

					console.log('res', res);

					return;
				}

				total_success += 1

				// If all ok create sales on POS 
				if (total_products === total_success && total_success > 0) {
					createPOSSales()
				}
			} else {
				console.log('res', res);

				// Show error
				setType('error')
				setTitle('oops')
				setMessage('Algo salio mal, revisa la información')
				setShowMessage(true)
			}
		}).catch((err) => {
			console.log('err', err);

			// Show error
			setType('error')
			setTitle('oops')
			setMessage('Algo salio mal, revisa la información')
			setShowMessage(true)
		})
	}

	// API / POS - Create sales on POS
	const createPOSSales = () => {
		axios({
			method: 'post',
			url: url_api + 'sales',
			data: {
				sales: successData.sales_per_vendor,
				key: process.env.REACT_APP_API_KEY
			},
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}).then((res) => {
			console.log('res', res);

			if (res.status === 200) {
				if (res.data.length < 1) {
					setType('info')
					setTitle('oops')
					setMessage('Ocurrió un error al guardar la venta - salesProducts')
					setShowMessage(true)

					console.log('res', res);

					return;
				}

				if (res.data.error) {
					setType('info')
					setTitle('oops')
					setMessage('Ocurrió un error al guardar la venta - salesProducts')
					setShowMessage(true)

					console.log('res', res);

					return;
				}
                register_sale_rating_on_item();
				// Clean data
				localStorage.removeItem('_address');
				localStorage.removeItem('CartProduct');
                
				history.push("/orderComplete")
			} else {
				console.log('res', res);

				// Show error
				setType('error')
				setTitle('oops')
				setMessage('Algo salio mal, revisa la información')
				setShowMessage(true)
			}
		}).catch((err) => {
			console.log('err', err);

			// Show error
			setType('error')
			setTitle('oops')
			setMessage('Algo salio mal, revisa la información')
			setShowMessage(true)
		})
	}

	const Pay = () => {
		let date = new Date()
		date.toLocaleString('es-MX', { timeZone: 'America/Mexico_City' });

		// Init vars
		const shipping = JSON.parse(localStorage.getItem("_shipping"))
		const address = JSON.parse(localStorage.getItem("_address"))
		const user = JSON.parse(localStorage.getItem("_user"))
		const products = GetCartItems()

		// Validations
		if (!user) {
			setType('info')
			setTitle('oops')
			setMessage('Necesitas iniciar sesión')
			setShowMessage(true)

			return;
		}

		// Build main sale data
		const main_sale = {
			total: GetCartItems().reduce((fr, CartItem) => fr + (CartItem.Qty * CartItem.Rate), 0),
			customer: user.id,
			date: date,
			data: {
				shipping: shipping,
				address: address,
				payment: {
					type: payment
				}
			},
		}

		// Build sales per vendor data
		const sales_per_vendor = products.reduce(function (obj, value) {
			var key = value.storeId;
			if (!obj[key]) {
				obj[key] = {
					total: (value.Qty * value.Rate),
					store: value.storeId,
					customer: user.id,
					father: null,
					date: date,
					data: {
						products: [{
							product: value.ProductID,
							name: value.ProductName,
							quantity: value.Qty,
							customer: user.id,
							price: value.Rate,
							main_sale: null,
							sale: null,
						}],
						shipping: shipping,
						address: address,
						customer: user,
						payment: {
							type: payment
						}
					}
				}
			} else {
				obj[key].total += (value.Qty * value.Rate)
				obj[key].data.products.push({
					product: value.ProductID,
					name: value.ProductName,
					quantity: value.Qty,
					customer: user.id,
					price: value.Rate,
					main_sale: null,
					sale: null,
				})
			}

			return obj;
		}, {});

		const saleData = {
			sales_per_vendor: sales_per_vendor,
			main_sale: main_sale,
		}

		console.log('total products', products.length);

		total_products = products.length
		total_success = 0

		mainSale(saleData)
	}


	const register_sale_rating_on_item = () =>{
		let products = GetCartItems();

		for (let index = 0; index < products.length; index++) {
			const element = products[index];
			let totalSalesShop = parseInt(element.ratingSales)+1;
            let idProductSales = parseInt(element.ProductID);
			axios({
				method: 'put',
				url: url_strapi + 'productos/'+idProductSales,
				data:{totalSales : totalSalesShop},
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}).then((res) => {
				console.log("res->",res);
			}).catch((err) => {
				//console.log('err', err);
			})
			
		}
	}

	/* Components
	======================================== */
	const GetAddress = () => {
		const address = JSON.parse(localStorage.getItem("_address"))

		return (
			<>
				<h6 className="text-primary mb-1">Envió</h6>
				<Card>
					<CardBody>
						<CardTitle tag="h5"><i className="las la-home"></i> {address.address_name}</CardTitle>
						<CardText>
							<InformationAddressColumOne data={address} /><br />
							<InformationAddressColumTwo data={address} /><br />
							<InformationAddressColumThree data={address} /><br />
						</CardText>
						<Link color="success" size="sm" to="/direction">
							<i className="las la-edit"></i> Cambiar
						</Link><br /><br />

						<CardTitle tag="h5"><i className="las la-truck"></i> Estándar</CardTitle>
						<CardText>
							$ 0
						</CardText>
					</CardBody>
				</Card>
			</>
		)
	}
	const GetPayment = () => {
		return (
			<>
				<SweetAlert
					type={type}
					title={title}
					text={message}
					show={showMessage}
					onConfirm={() => setShowMessage(false)}
				/>
				<h6 className="text-primary mb-1">Pago</h6>
				<Card>
					<CardBody>
						<Row>
							<Col >
								<div className="d-flex justify-content-between align-items-center border-bottom py-3">
									<span className="text-muted">Subtotal</span>
									<span className="text-dark">
										${GetCartItems().reduce((fr, CartItem) => fr + (CartItem.Qty * CartItem.Rate), 0).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}
									</span>
								</div>
								<div className="d-flex justify-content-between align-items-center border-bottom py-3">
									<span className="text-muted">Tax</span>
									<span className="text-dark">$0</span>
								</div>
								<div className="d-flex justify-content-between align-items-center border-bottom py-3">
									<span className="text-muted">Envió</span>
									<span className="text-dark">$0</span>
								</div>
								<div className="d-flex justify-content-between align-items-center pt-3 mb-5">
									<span className="text-dark">Total</span>
									<span className="text-dark font-weight-bold">
										${GetCartItems().reduce((fr, CartItem) => fr + (CartItem.Qty * CartItem.Rate), 0).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}
									</span>
								</div>
								<h5>Método de pago</h5>
								<select
									className="custom-select border-0 rounded-0 bg-light form-control d-lg-inline"
									value={payment}
									onChange={e => setPayment(e.target.value)}
								>
									<option key={`upon_delivery`} value="upon_delivery">Contra entrega</option>
								</select><br /><br />
								<Button className="btn btn-primary mr-3" onClick={Pay} block>Finalizar compra</Button>
							</Col>
						</Row>
					</CardBody>
				</Card>
			</>
		)
	}

	return (
		<div className="page-content">
			<section>

				<Container>
					{(GetCartItems() !== null && GetCartItems().length > 0) ?
						<Row>
							<Col md={12} lg={12}>
								<Row>
									<Col md={6}>
										<GetAddress />
									</Col>
									<Col md={6}>
										<GetPayment />
									</Col>
								</Row>
							</Col>
							<Col md={12} lg={12} style={{ paddingTop: 50 }}>
								<h6 className="text-primary mb-1">Carrito</h6>
							</Col>
							<div className="table-responsive">
								<table className="cart-table table">
									<thead>
										<tr>
											<th scope="col">Producto</th>
											<th scope="col">Precio</th>
											<th scope="col">Cantidad</th>
											<th scope="col">Total</th>
										</tr>
									</thead>
									<tbody>
										{GetCartItems().map((CartItem, index) => (
											<tr key={index}>
												<td>
													<div className="media align-items-center">
														<Link to="#">
															<img className="img-fluid" src={`${CartItem.ProductImage}`} style={{ height: '50px', width: '50px' }} alt="" />
														</Link>
														<div className="media-body ml-3">
															<div className="product-title mb-2"><Link className="link-title" to="#">{CartItem.ProductName}</Link>
															</div>
														</div>
													</div>
												</td>
												<td> <span className="product-price text-muted">
													${CartItem.Rate.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}
												</span>
												</td>
												<td>
													<div className="d-flex justify-content-center align-items-center">
														{CartItem.Qty}
													</div>
												</td>
												<td>
													<span className="product-price text-dark font-w-6">
														${(CartItem.Rate * CartItem.Qty).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}
													</span>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</Row>
						:
						<Row>
							<Col md={12} className="text-center pb-11">
								<h3 className="mb-4">Su carrito está vacío.</h3>
								<Link className="btn btn-primary mr-3" to="/">Inicio</Link>
								<Link className="btn btn-primary" to="/productsCategories">Continuar comprando</Link>
							</Col>
						</Row>
					}
				</Container>
			</section>
		</div>
	)
}

export default Summary;