import React from 'react';
import { Table } from 'reactstrap';

const LevelsPrice = ({levels_price}) => {

    //console.log("levels_price->",levels_price);
    if(levels_price !== null){
        if(levels_price.length > 0 ){
            let levels_price_obj = JSON.parse(levels_price);
            let count = 0;
            return (
                <>
                    <h5>Niveles de precio</h5>
                    <Table responsive>
                        <thead>
                            <tr>
                            <th>Cantidad</th>
                            <th>Precio</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.values(levels_price_obj).map(e =>
                                <tr key={count+=1}>
                                    <td>{e.quantity}</td>
                                    <td>${' '}{e.price}{' x pz'}</td> 
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </>
            );
        }
    }
   
  return (<></>);
}

export default LevelsPrice;