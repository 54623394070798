import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import SweetAlert from 'sweetalert2-react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';

import Pageheading from '../../widgets/pageheading';
import PhoneNumber from './PhoneNumber';

const SignUp = ({ token }) => {
	/* Hooks
	======================================== */
	const [className, setClassName] = useState('btn btn-default')
	const [showMessage, setShowMessage] = useState(false)
	const [validPhone, setValidPhone] = useState(false)
	const [phoneNumber, setPhoneNumber] = useState('')
	const [disabled, setDisabled] = useState(true)
	const [type, setType] = useState('success')
	const [message, setMessage] = useState('')
	const [title, setTitle] = useState('')
	const [data, setData] = useState({
		country: 'MX',
		last_name: '',
		name: '',
		mail: '',
		pass2: '',
		pass: '',
		tel: '',
	})

	/* Observers
	======================================== */
	useEffect(() => {
		if (disabled) {
			setClassName('btn btn-default');
		} else {
			setClassName('btn btn-primary');
		}

		// Set customer token
		if (!data.token) {
			const TokenGenerator = require('uuid-token-generator');
			const _token = new TokenGenerator(256, TokenGenerator.BASE62);
			const customer_token = _token.generate();

			setData({
				...data,
				'token': customer_token,
				tel: phoneNumber
			});
		}
	}, [disabled, data, phoneNumber])

	/* Functions
	======================================== */
	// Function that ejecute when the user write an input
	const updateData = e => {
		setData({
			...data,
			[e.target.name]: e.target.value
		});
	}
	const updatePhone = () => {
		setData({
			...data,
			tel: phoneNumber
		});
	}

	// Send to login
	let history = useHistory();
	const goLogin = () => {
		history.push("/login")
	}

	const setPhoneNumberCheck = (number) => {
		setData({ ...data, tel: number });
	}

	/*the user is searched in the database */
	const Userexists = () => {
		const url = (window.location.hostname === 'localhost') ? 'http://localhost:1337/customers' : 'https://backend.mayofy.com/customers';

		axios({
			method: 'get',
			url: url,
			params: { mail: data.mail },
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}).then((res) => {
			if (res.status === 200) {
				console.log(res);
				if (res.data.length === 1) {
					setType('info')
					setTitle('oops')
					setMessage('El email ya fue registrado intente con otro email.')
					setShowMessage(true)
					return;
				}

			} else {
				console.log('res', res);
			}
		}).catch((err) => {
			console.log('err', err);
		})
	}

	// API - Create customer
	const createCustomer = (e) => {
		e.preventDefault();

		if (data.pass !== data.pass2) {
			// Show error
			setType('info')
			setTitle('oops')
			setMessage('Las contraseñas no son iguales')
			setShowMessage(true)

			return;
		}

		Userexists();

		//console.log('data', data);
		const url = (window.location.hostname === 'localhost') ? 'http://localhost:1337/customers' : 'https://backend.mayofy.com/customers';

		axios({
			method: 'post',
			url: url,
			data: data,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}).then((res) => {
			if (res.status === 200) {
				// Show alert
				setType('success')
				setTitle('Registro exitoso')
				setMessage('Tu usuario ha sido registrado con éxito')
				setShowMessage(true)

				// Clear form
				setData({
					country: 'MX',
					name: '',
					mail: '',
					pass2: '',
					pass: '',
					tel: '',
				})
				document.getElementById("agreeTerms").checked = false

				// Restore hook
				setDisabled(true)

				// Go to login
				goLogin()
			} else {
				console.log('res', res);

				// Show error
				setType('error')
				setTitle('oops')
				setMessage('Algo salio mal, revisa la información')
				setShowMessage(true)
			}
		}).catch((err) => {
			console.log('err', err);

			// Show error
			setType('error')
			setTitle('oops')
			setMessage('Algo salio mal, revisa la información')
			setShowMessage(true)
		})
	}

	return (
		<>
			<SweetAlert
				type={type}
				title={title}
				text={message}
				show={showMessage}
				onConfirm={() => setShowMessage(false)}
			/>
			{!validPhone ?
				<PhoneNumber
					token={token}
					setPhoneNumber={setPhoneNumber}
					setValidPhone={setValidPhone}
					setPhoneNumberCheck={setPhoneNumberCheck}
				/>
				:
				<>
					<section className="bg-light page_information">
						<Pageheading foldername={"Pages"} title={"Sign Up"} />
					</section>
					<div className="page-content">
						<section className="register">
							<Container>
								<Row className="justify-content-center text-center">
									<Col lg={8} md={12}>
										<div className="mb-6">
											<p className="lead">Todos los campos son requeridos.</p>
										</div>
									</Col>
								</Row>
								<Row>
									<Col lg={8} md={10} className="ml-auto mr-auto">
										<div className="register-form text-center">
											<form id="contact-form" method="post" onSubmit={createCustomer}>
												<Row>
													<Col md={6}>
														<div className="form-group">
															<input
																data-error="El nombre es requerido."
																className="form-control"
																placeholder="Nombre"
																required="required"
																name="name"
																type="text"

																onChange={updateData}
																value={data.name}
															/>
															<div className="help-block with-errors" />
														</div>
													</Col>
													<Col md={6}>
														<div className="form-group">
															<input
																data-error="El apellido es requerido."
																className="form-control"
																placeholder="Apellido"
																required="required"
																name="last_name"
																type="text"

																onChange={updateData}
																value={data.last_name}
															/>
															<div className="help-block with-errors" />
														</div>
													</Col>
													<Col md={6}>
														<div className="form-group">
															<input
																data-error="El correo es requerido."
																placeholder="alguien@mayofy.com"
																className="form-control"
																required="required"
																type="email"
																name="mail"

																onChange={updateData}
																value={data.mail}
															/>
															<div className="help-block with-errors" />
														</div>
													</Col>
												</Row>
												<Row>
													<Col md={6}>
														<div className="form-group">
															<input
																data-error="El teléfono es requerido"
																className="form-control"
																placeholder="0123456789"
																required="required"
																readOnly="true"
																name="tel"
																type="tel"

																onChange={updatePhone}
																value={phoneNumber}
															/>
														</div>
													</Col>
													<Col md={6}>
														<div className="form-group">
															<select
																className="form-control"
																name="country"

																onChange={updateData}
																value={data.country}
															>
																<option value="MX">Mexico</option>
																<option value="CHN">China</option>
																<option value="AR">Argentina</option>
																<option value="US">United States</option>
															</select>
														</div>
													</Col>
												</Row>
												<Row>
													<Col md={6}>
														<div className="form-group">
															<input
																type="password"
																name="pass"
																className="form-control"
																placeholder="Contraseña"
																required="required"
																data-error="La contraseña es requerida."

																onChange={updateData}
																value={data.pass}
															/>
														</div>
													</Col>
													<Col md={6}>
														<div className="form-group">
															<input
																type="password"
																name="pass2"
																className="form-control"
																placeholder="Confirmar contraseña"
																required="required"
																data-error="La contraseña es requerida."

																onChange={updateData}
																value={data.pass2}
															/>
														</div>
													</Col>
												</Row>
												<Row className="mt-5">
													<Col md={12}>
														<div className="remember-checkbox clearfix mb-5">
															<div className="custom-control custom-checkbox">
																<input
																	id="agreeTerms"
																	name="agreeTerms"
																	type="checkbox"
																	className="custom-control-input"
																	onChange={e => setDisabled(!e.target.checked)}
																/>
																<label className="custom-control-label" htmlFor="agreeTerms">
																	Estoy de acuerdo con los términos, condiciones y políticas de privacidad
																</label>
															</div>
														</div>
													</Col>
												</Row>
												<Row>
													<Col md={12}>
														<button type="submit" className={className} disabled={disabled}>
															Create Account
														</button>
														<span className="mt-4 d-block">
															¿Ya tienes cuenta? <Link to="/login"><i>Inicia sesión</i></Link>
														</span>
													</Col>
												</Row>
											</form>
										</div>
									</Col>
								</Row>
							</Container>
						</section>
					</div>
				</>
			}
		</>
	);
}

export default SignUp;