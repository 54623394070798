import React, { useState } from 'react';
import { Container, Row } from 'reactstrap';
import SweetAlert from 'sweetalert2-react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';

import Pageheading from '../../widgets/pageheading';

const Login = ({ token }) => {
    
    /* Hooks
    ======================================== */
    const [showMessage, setShowMessage] = useState(false)
    const [type, setType] = useState('success')
    const [message, setMessage] = useState('')
    const [title, setTitle] = useState('')
    const [data, setData] = useState({
        mail: '',
        pass: ''
    })

    /* Functions
    ======================================== */

    // Function that ejecute when the user write an input
    const updateData = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }

    // API - New user
    const handleSubmit = (e) => {
        e.preventDefault();

        console.log('data', data);

        const url = (window.location.hostname === 'localhost') ? 'http://localhost:1337/customers' : 'https://backend.mayofy.com/customers';

        axios({
            method: 'get',
            params: data,
            url: url,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        }).then((res) => {
            if (res.status === 200) {
                if (res.data.length < 1) {
                    setType('info')
                    setTitle('oops')
                    setMessage('Mail o contraseña incorrectos')
                    setShowMessage(true)

                    return;
                }

                // Save user
                localStorage.setItem("_user", JSON.stringify(res.data[0]));

                // Show alert
                setType('success')
                setTitle('Registro exitoso')
                setMessage('Tu usuario ha sido registrado con éxito')
                setShowMessage(true)

                // Clear form
                setData({
                    mail: '',
                    pass: '',
                })

                // Redirect to home
                goHome()
            } else {
                console.log('res', res);

                // Show error
                setType('error')
                setTitle('oops')
                setMessage('Algo salio mal, revisa la información')
                setShowMessage(true)
            }
        }).catch((err) => {
            console.log('err', err);

            // Show error
            setType('error')
            setTitle('oops')
            setMessage('Algo salio mal, revisa la información')
            setShowMessage(true)
        })
    }

    // Send login user to home
    let history = useHistory();
    const goHome = () => {
        if (localStorage.getItem("CartProduct")) {
            history.push("/cart");
        }else{
            history.push("/");
        }
    }

    return (
        <>
            <SweetAlert
                type={type}
                title={title}
                text={message}
                show={showMessage}
                onConfirm={() => setShowMessage(false)}
            />
            <section className="bg-light page_information">
                <Pageheading foldername={"Pages"} title={"Login"} />
            </section>
            <div className="page-content">
                <section>
                    <Container>
                        <Row className="justify-content-center">
                            <div className="col-lg-5">
                                <div className="shadow p-3">
                                    <form id="contact-form" method="post" onSubmit={handleSubmit}>
                                        <div className="messages" />
                                        <div className="form-group">
                                            <input
                                                id="mail"
                                                type="text"
                                                name="mail"
                                                className="form-control"
                                                placeholder="alguien@ecommpos.com"
                                                required="required"
                                                data-error="Email is required."

                                                onChange={updateData}
                                                value={data.mail}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                id="pass"
                                                type="password"
                                                name="pass"
                                                className="form-control"
                                                placeholder="Password"
                                                required="required"
                                                data-error="password is required."

                                                onChange={updateData}
                                                value={data.pass}
                                            />
                                        </div>
                                        <div className="form-group mt-4 mb-5">
                                            <div className="remember-checkbox d-flex align-items-center justify-content-between">
                                                <Link to="/forgot-password">Recuperar contraseña</Link>
                                            </div>
                                        </div>
                                        <button type="submit" className="btn btn-primary">
                                            Iniciar sesión
                                        </button>
                                    </form>
                                    <div className="d-flex align-items-center text-center justify-content-center mt-4">
                                        <span className="text-muted mr-1">¿No tienes cuenta?</span>
                                        <Link to="/sign-up">Crear cuenta</Link>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </Container>
                </section>
            </div>
        </>
    );
}

export default Login;