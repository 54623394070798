import React,{useState,useEffect} from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { Link } from 'react-router-dom';
import { useQuery } from "@apollo/client";
import { FILTER_BY_STATUS } from '../../graphql/queries/categories';

function SlideBarCategoires (){
    const [dataCat, setDataCat] = useState({});

    const {data } = useQuery(FILTER_BY_STATUS, {
        variables: {
            Status: 0
        }
    });
    
    useEffect(() => {
        if(data){
            setDataCat(data.categoriasConnection.values);
        }
    }, [data]);

    return(
        <div className="shadow-sm p-5">
            <div className="widget widget-categories mb-4 pb-4 border-bottom">
                <h4 className="widget-title mb-3">Categorias</h4>
                <Scrollbars style={{  height: 300 }}>   
                <ul className="list-unstyled">  
                    <li key={`categoryAll`}>
                        <Link to={`/category/All/All`} > <i className="lar la-circle"></i>Todas las categorías</Link>
                    </li>                                                  
                    {dataCat.length !== undefined && dataCat.map((categoria, i) => (
                        <li key={`categorY_${i}`}>
                            <Link to={`/category/${categoria.Nombre}/${categoria.id}`}> <i className="lar la-circle"></i> {categoria.Nombre}</Link>
                        </li>
                    ))}
                </ul> 
                </Scrollbars>
            </div>
        </div>
    );
}

export default React.memo(SlideBarCategoires);

