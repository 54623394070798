import React, { useState} from 'react';
import {ListGroup , ListGroupItem,TabContent,TabPane,Row,Col,Card,Button} from "reactstrap";
import Viewer from "react-viewer";

const SliderViewerImages =({img,sourceImageUrls})=>{
    const [activeTabImg, setActiveTabImg] = useState('0');
    const [visiblePc, setVisiblePc] = useState(false);
    const [visibleMobil, setVisibleMobil] = useState(false);

    const SeeImageInFullScreenPc = () => {
        return (
            <div>
                <Button  
                    className={`btn btn-primary product-details-Zoom-pc`} 
                    onClick={() => {setVisiblePc(!visiblePc);}} 
                    color="info">
                    <i className="las la-search-plus mr-1"></i>
                </Button>
                <Viewer
                visible={visiblePc}
                onClose={() => {setVisiblePc(false);}}
                images={sourceImageUrls}
                />
            </div>
        )
    }

    const SeeImageInFullScreenMobile = () => {
        return (
            <div>
                <Button  
                    className={`btn btn-primary product-details-Zoom-mobil `} 
                    onClick={() => {setVisibleMobil(!visibleMobil);}} 
                    color="info">
                    <i className="las la-search-plus mr-1"></i>
                </Button>
                <Viewer
                visible={visibleMobil}
                onClose={() => {setVisibleMobil(false);}}
                images={sourceImageUrls}
                />
            </div>
        )
    }

    return(
        <>
            <div className="col-lg-1 col-2 thubPcImgDetailsProduct">
                <ListGroup>
                    {img.map((image, i) => (
                        <ListGroupItem  className="listItemImg" tag="a"  index={i} onClick={()=>setActiveTabImg(i.toString())}  >
                            <img  src={`${image.image}`} alt="" width="50" height="50" style={{verticalAlign:"middle"}} />
                        </ListGroupItem>
                    ))}
                </ListGroup>
            </div>
            <div className="col-lg-5 col-12">
                <TabContent className="tabDetailsImg" activeTab={activeTabImg} >
                    {img.map((image, i) => (
                        <TabPane tabId={`${i}`} >
                            <Row > 
                                <Col sm="12">
                                    <img src={`${image.image}`} width="100%" height="450" alt="" />
                                </Col>
                            </Row>
                        </TabPane>
                    ))}
                </TabContent>
                <div className="thubPcImgDetailsProduct">
                    <SeeImageInFullScreenPc  />
                </div>
                <Row className="d-lg-none">
                    <div className="col-3">
                        <SeeImageInFullScreenMobile />
                    </div>
                    {img.map((image, i) => (
                        <div className="col-3" index={i} onClick={()=>setActiveTabImg(i.toString())} >
                            <Card body width="50" className="listItemImg">
                                <img src={`${image.image}`} alt="" width="50" height="50" style={{verticalAlign:"middle"}} />
                            </Card>
                        </div>
                    ))}
                </Row>
            </div>
        </>
    )
}

export default  SliderViewerImages;