import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { All_CATEGORIES } from '../../../graphql/queries/categories';
import { Row, Col, Container } from 'reactstrap';

import GifLoader from '../../../gifLoader/gifloader';

import Pageheading from '../../../widgets/pageheading';
import CardImage from '../../../components/CardImage';
import default_image from '../../../assets/images/default-image.png'; 

function ProductsCategories(props) {
    const url = (window.location.hostname === 'localhost') ? 'http://localhost:1337' : 'https://backend.mayofy.com';
    const [view] = useState('grid')
    const [categoriesData, setCategoriesData] = useState([]);
    const { data: categories, loading } = useQuery(All_CATEGORIES);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (categories) {
            setCategoriesData(categories.categorias);
        }
    }, [categories]);

    return (
        <>
            {/*hero section start*/}
            <section className="bg-light page_information">
                <Pageheading foldername={"Shop"} title={"Categorías de productos"} />
            </section>
            <div className="page-content">
                {loading ?
                    <GifLoader />
                    :
                    <section>
                        <Container>
                            <Row>
                                {categoriesData.map((category, i) => (
                                    <Col lg={3} md={4} key={i}>
                                        {view === "grid" ?
                                            <CardImage
                                                button_text='Visitar'
                                                link={'/category/' + category.Nombre + '/' + category.id}
                                                title={category.Nombre}
                                                image={category.image === null ? default_image : url+category.image['url']}
                                                text={""}
                                            />
                                            :
                                            null
                                        }
                                    </Col>
                                ))}
                            </Row>
                        </Container>
                    </section>
                }
            </div>
        </>
    );
}


export default React.memo(ProductsCategories);