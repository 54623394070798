import React from 'react';
import HelperDirection from './helperForAddresses';
import Pageheading from '../../widgets/pageheading';

function HomeDirection({token}){
    return(
        <>
            <section className="bg-light page_information">
                <Pageheading foldername={"cuenta"} title={"Direcciones"} />
            </section>
            <HelperDirection token={token} checkOut={true} />
        </>
    );
}

export default React.memo(HomeDirection);