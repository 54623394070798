import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import Pageheading from '../../widgets/pageheading';

const OrderComplete = () => {
	return (
		<>
			<section className="bg-light page_information">
				<Pageheading foldername={"Shop"} title={"Orden completa"} />
			</section>
			<div className="page-content">
				<section className="text-center pb-11">
					<Container>
						<Row>
							<Col md={12}>
								<h3 className="mb-4">Gracias por su compra, su pedido esta en proceso</h3>
								<Link className="btn btn-primary btn-animated" to="/customer"><i className="las la-list-alt mr-1" />Mis compras</Link> 
								<span>&nbsp;&nbsp;</span>
								<Link className="btn btn-dark btn-animated" to="/productsCategories"><i className="las la-shopping-cart mr-1" />Continuar comprando</Link>
							</Col>
						</Row>
					</Container>
				</section>
			</div>
		</>
	);
}

export default OrderComplete;